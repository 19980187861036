<div class="container">
    <div class="version" *ngIf="data.entry === 'version'">
        <div class="title">
            <h3>Actualización</h3>
        </div>
        <div class="fields">
            <p class="nth1">¡Hay una nueva versión disponible,<br> debes actualizar la aplicación!</p>

            <p class="nth2">La nueva version es {{data.version}}</p>

            <ol class="nth3">
                <li>Para actualizar manualmente la aplicación, por favor recargar la pagina.</li>
                <li>En caso de tener la aplicación instalada, cerrar y volver a abrir la aplicación.</li>
                <li>En ultimo caso desinstalar la aplicación y volver a instalarla.</li>
            </ol>
        </div>
    </div>

    <div class="logo"><img width="75" src="../../../assets/images/spark.svg"></div>
    <div>
        <button mat-raised-button color="primary" class="exit-button btnlarge" type="button" (click)="exit()">
            SALIR
        </button>
    </div>
</div>