import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient,HttpParams } from '@angular/common/http';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { PickupService } from 'src/app/services/pickup.service';
// import Quagga from 'quagga';

@Component({
  selector: 'app-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.scss']
})
export class PickerComponent implements OnInit {

  errorMessage:string='';
  codigoBarra:string='';
  posicion:string='';

  constructor(private router: Router,public pickupService : PickupService, public utilityService : UtilitiesService) { 


  }

  ngOnInit() {

    this.scan();
  }

  scan(){

    this.codigoBarra="";

    // Quagga.init({
    //   inputStream: {
    //     constraints: {
    //       facingMode: 'environment' // restrict camera type
    //     },
    //     area: { // defines rectangle of the detection
    //       top: '40%',    // top offset
    //       right: '0%',  // right offset
    //       left: '0%',   // left offset
    //       bottom: '40%'  // bottom offset
    //     },
    //   },
    //   decoder: {
    //     readers: ['ean_reader'] // restrict code types
    //   },
    // },
    // (err:any) => {
    //   if (err) {
    //     this.errorMessage = `No se puede inicializar el lector, err: ${err}`;
    //   } else {
    //     Quagga.start();
    //     Quagga.onDetected((res:any) => {
    //      // window.alert(`code: ${res.codeResult.code}`);
    //       this.codigoBarra=res.codeResult.code;
    //     })
    //   }
    // });
  }

  

  enviarPosicion(){
    this.posicion="";
    this.codigoBarra="";
  }

  stopScan(){
    // Quagga.stop();
  }

}
