import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent {

  constructor(public dialogRef: MatDialogRef<DisclaimerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }


  exit(){
    this.dialogRef.close();
  }
}
