import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { MatTableModule } from '@angular/material/table';
import { Pedido } from 'src/app/interfaces/Pedido';
@Component({
  selector: 'app-recepcion-bt',
  templateUrl: './recepcion-bt.component.html',
  styleUrls: ['./recepcion-bt.component.scss']
})
export class RecepcionBtComponent {

  pedidosPreparacion:Pedido[] = [];
  paramBuscar:any;
  num_encontrados:number=0;

  displayedColumns: string[] = ['codigo_bulto', 'descripcion', 'ubicacion'];

  constructor(public http: HttpClient,private router: Router,public pickupService : PickupService){}

  goPicker(item:any){
    this.pickupService.pedidoActivo = item;
    sessionStorage.setItem('pedidoActivo', JSON.stringify(item));
    this.router.navigate(['/picker-manual']);
  }

  async ngOnInit(){
    if(!this.pickupService.usuario.id) this.router.navigate(['/login']);

    if(!this.pickupService.pedidosPreparacion.length){
      this.pickupService.programados_page = 1;
      await this.pickupService.syncronizarEnEspera();
      await this.pickupService.syncronizarBD();
    }
    this.filterPedidos();
  }

  filterPedidos(){
    this.pedidosPreparacion = this.pickupService.pedidosPreparacion.filter((pedido:Pedido) => {
      console.log(pedido);
      return pedido.tipo == 'BT' && pedido.productos.some((producto:any) => !producto.ubicacion);
    });
  }

  buscarPedido(event: any){}

  cumpleCriterioBusqueda(item:any){
    if(this.paramBuscar){

      if(item.sg123.toLowerCase().includes(this.paramBuscar.toLowerCase()) 
        || item.nombrecliente.toLowerCase().includes(this.paramBuscar.toLowerCase())
        || item.rut.toLowerCase().includes(this.paramBuscar.toLowerCase())){
        return true
      }else{
          return false;
      }
    }
    return true;
  }

  test(){
    const headers = { 'Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'*', 'Authorization' :  '30f0934fa9573d863475af990e62a4a2' };

    let payload={
    "labelNumber": 121212121212
    }

    this.http.post('https://clicyretira.ubq.cl/api/set-entrega-transportista',payload,{headers}).subscribe(resp => {
      console.log('enviado...');
    },error =>{
      console.log('error...');
    })
  }

}