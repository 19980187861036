import { Component } from '@angular/core';
import { PickupService } from 'src/app/services/pickup.service';

@Component({
  selector: 'app-rut-runner',
  templateUrl: './rut-runner.component.html',
  styleUrls: ['./rut-runner.component.scss']
})
export class RutRunnerComponent {

  constructor(public pickupService : PickupService) { }

}
