<div class="loader" *ngIf="pickupService.pedidoActivo.loader_entrega === 'entregando'"><mat-spinner diameter="250"></mat-spinner></div>
<section>
    <div class="container">
        <div class="entregado" *ngIf="edit_mode">
            <div class="edicion">
                <p>✏️</p>
                <h3 class="edit">Editando Información</h3>
                <p>✏️</p>
            </div>
        </div>
        <mat-card class="edit" *ngIf="edit_mode">
            <mat-card-header>
                <mat-card-title>Ingresar Información</mat-card-title>
            </mat-card-header>
            <mat-card-content class="edit">
                <div>
                    <mat-form-field class="example-full-width">
                        <mat-label>Comentario</mat-label>
                        <textarea #comentario matInput placeholder="..." maxlength="500"></textarea>
                        <mat-hint align="start">*Ingrese cualquier información relevante para la pos-revision del pedido.</mat-hint>
                        <mat-hint align="end">{{comentario.value.length}} / 500</mat-hint>
                    </mat-form-field>
                </div>

            </mat-card-content>
            <mat-card-footer>
                <button
                    mat-raised-button
                    class="button btnlarge"
                    [disabled]="comentario.value.length < 1"
                    (click)="editComment()"
                >
                <mat-icon>edit_note</mat-icon> Enviar Información
                </button>
            </mat-card-footer>
        </mat-card>
        <mat-card class="entrega" *ngIf="!edit_mode && pickupService.pedidoActivo.loader_entrega === 'exito'">
            <mat-card-content class="entrega">
                <div class="entrega">
                    <img src="../../../../assets/images/check.png">
                    <h3 class="entrega">Pedido entregado Exitosamente</h3>
                </div>
            </mat-card-content>
            <mat-card-footer>
                <button mat-raised-button color="primary" class="button btnlarge" (click)="comentar()" >Agregar Comentarios</button>
            </mat-card-footer>
        </mat-card>
        <mat-card class="entrega-error" *ngIf="!edit_mode && pickupService.pedidoActivo.loader_entrega === 'error'">
            <mat-card-content class="entrega-error">
                <div class="entrega-error">
                    <img src="../../../../assets/images/warning.ico">
                    <h3 class="entrega-error">Error al entregar la orden, por favor intentelo nuevamente</h3>
                </div>
            </mat-card-content>
            <mat-card-footer>
                <button mat-raised-button color="primary" class="button btnlarge" (click)="reintentar()" >Reintentar</button>
            </mat-card-footer>
        </mat-card>
    </div>
    <div class="exit">
        <button *ngIf="pickupService.pedidoActivo.loader_entrega !== 'error' && pickupService.pedidoActivo.loader_entrega !== 'entregando'" mat-raised-button color="primary" class="button btnlarge" (click)="salir()" >No Comentar y Salir</button>
        <button *ngIf="pickupService.pedidoActivo.loader_entrega === 'error'" mat-raised-button color="primary" class="button btnlarge" (click)="salir()" >No Reintentar y Salir</button>
    </div>
</section>