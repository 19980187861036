import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-inforetira',
  templateUrl: './inforetira.component.html',
  styleUrls: ['./inforetira.component.scss']
})
export class InforetiraComponent {

  constructor(public dialog: MatDialog, private dialogRef: MatDialogRef<InforetiraComponent>){
    dialogRef.disableClose = true;
  }

  close(){
    this.dialogRef.close();
  }

}
