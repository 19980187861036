<div class="container">
    <h3> RunnerApp<br> <span style="font-size: 21px;">by Ubiquity</span> </h3>
   
    <mat-list role="list">
        <mat-list-item role="listitem">
            <button mat-raised-button color="primary" (click)="(gotoInicio())"><mat-icon>home</mat-icon>Inicio</button>
        </mat-list-item>
        <mat-list-item role="listitem">
            <button mat-raised-button color="primary" (click)="(gotoBuscar())"><mat-icon>search</mat-icon>Buscar</button>
        </mat-list-item>
        <mat-list-item role="listitem">
            <button mat-raised-button color="primary" (click)="(gotoColaEnvio())"><mat-icon>hourglass_empty</mat-icon>Cola de envio</button>
        </mat-list-item>
        <!-- <mat-list-item role="listitem">
            <button mat-raised-button color="primary" (click)="(gotoBuscar())">Ayuda y sugerencias</button>
        </mat-list-item> -->
        <!-- <mat-list-item role="listitem">
            <a mat-raised-button class="bg-success button" href="tel:+{{ pickupService.pedidoActivo.celular }}">Soporte</a>
        </mat-list-item> -->
        <mat-list-item role="listitem" style="text-align: left;">
            <a href="https://wa.me/56957106897" mat-raised-button color="primary" (click)="goSoporte()"><mat-icon>help</mat-icon>Ayuda y soporte</a>
        </mat-list-item>
        <mat-list-item role="listitem">
            <button mat-raised-button color="warn" (click)="(gotoLogin())"><mat-icon>exit_to_app</mat-icon>Cerrar Sesion</button>
        </mat-list-item>
    <mat-list-item role="listitem" style="padding-top:10px; padding-bottom: 10px;">
        <mat-slide-toggle [(ngModel)]="pickupService.llamado_activado" (change)="setSilenciado()">Recibir alertas telefónicas</mat-slide-toggle>
    </mat-list-item>
    </mat-list>
    <div>
        <p>Version: {{pickupService.appVersion}}</p>
    </div>
    <div class="powered"><img src="../../../assets/images/ubq.png"></div>
</div>