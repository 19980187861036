<section>
    <h3 class="sectionTitle">
        <mat-icon>shopping_basket</mat-icon> Rechazo bulto<b>{{ pickupService.codigoPosicion }}</b>
    </h3>     
    <p *ngIf="bultos.length==0" class="alert alert-nocontent">
        No hay bultos asignados
    </p>
    <div>
        <mat-card >
            <mat-card-header> 
                <mat-card-title>{{ pickupService.pedidoActivo.nombrecliente }}</mat-card-title> 
                <mat-card-subtitle>{{ pickupService.pedidoActivo.sg123 }}</mat-card-subtitle> 
            </mat-card-header> 
            <mat-card-content>  
                <table>
                    <tr>
                        <td><mat-icon>local_play</mat-icon></td>
                        <td>Orden</td>
                        <td>{{ pickupService.pedidoActivo.orden }}</td>
                    </tr>
                    <tr>
                        <td><mat-icon>person</mat-icon></td>
                        <td>Email</td>
                        <td>{{ pickupService.pedidoActivo.email }}</td>
                    </tr>
                    <tr>
                        <td><mat-icon>event_available</mat-icon></td>
                        <td>Fecha retiro</td>
                        <td>{{ pickupService.pedidoActivo.fecharetiro }}</td>
                    </tr>
                    <tr>
                        <td><mat-icon>access_time</mat-icon></td>
                        <td>Ventana</td>
                        <td>{{ pickupService.pedidoActivo.ventana }}</td>
                    </tr>
                    <tr *ngIf="pickupService.pedidoActivo.id_runner>0">
                        <td><mat-icon>directions_run</mat-icon></td>
                        <td>Runner asignado</td>
                        <td>{{ pickupService.pedidoActivo.runner }}</td>
                    </tr> 
                    <tr *ngIf="pickupService.pedidoActivo.status=='Cliente esperando'">
                        <td><mat-icon>person_pin_circle</mat-icon></td>
                        <td>Ubicación cliente</td>
                        <td>{{ pickupService.pedidoActivo.zona_entrega }}</td>
                    </tr>
                    <tr *ngIf="pickupService.pedidoActivo.ubicacion_bodega!='' && pickupService.pedidoActivo.ubicacion_bodega">
                        <td><mat-icon>location_on</mat-icon></td>
                        <td>Ubicación bodega</td>
                        <td>{{ pickupService.pedidoActivo.ubicacion_bodega }}</td>
                    </tr> 
                    <tr *ngIf="pickupService.pedidoActivo.modo_retiro">
                        <td><mat-icon>store_mall_directory</mat-icon></td>
                        <td>Modo retiro</td>
                        <td>{{ pickupService.pedidoActivo.modo_retiro }}</td>
                    </tr>
                    <tr *ngIf="pickupService.pedidoActivo.status=='Cliente esperando'">
                        <td><mat-icon>timer</mat-icon></td>
                        <td>Tiempo de espera</td>
                        <td>{{ pickupService.pedidoActivo.tiempo }}</td>
                    </tr>
                    <tr>
                        <td><mat-icon>insert_comment</mat-icon></td>
                        <td>Comentario</td>
                        <td>{{ pickupService.pedidoActivo.comentario_runner }}</td>
                    </tr>
                </table>
            </mat-card-content>
            <mat-card-footer>
            </mat-card-footer>
        </mat-card>
    </div>
    <div *ngIf="bultos.length>0">
        <mat-card  *ngFor="let bulto of bultos">
            <mat-card-header>
                <mat-card-title>{{ bulto.id }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form>
                    <select class="select-full-width" placeholder="PRODUCTO ACEPTADO" (ionChange)="handleChange($event,bulto.id)" value="{{bulto.id_rechazo }}">
                        <option value="0">PRODUCTO ACEPTADO</option>
                        <option value="1">RECHAZADO POR MERCADERIA CON DAÑOS</option>
                        <option value="2">RECHAZADO POR ERROR DE PEDIDO</option>
                        <option value="3">RETIRO PARCIAL DE PEDIDO</option>
                        <option value="4">OTRO</option>
                    </select>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</section>