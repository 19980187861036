<div class="loader" *ngIf="loginService.login_loader"><mat-spinner diameter="250"></mat-spinner></div>
<div class="container">
    <header class="header">
        <img src="assets/images/logoacuenta.png" height="50%"/>
    </header>
    <main class="main-login">
        <!-- INGRESO NORMAL -->
        <form #form="ngForm" (ngSubmit)="login(form)" >
            <div class="title">
                <h3>Bienvenido</h3>
            </div>
            <div class="fields">
                <mat-form-field appearance="fill">
                    <mat-label>Nombre de usuario</mat-label>
                    <input #email name="email" type="email"  title="Nombre de usuario" matInput ngModel required>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Contraseña</mat-label>
                    <input name="password" matInput [type]="hide_password ? 'password' : 'text'" ngModel required>
                    <button mat-icon-button matSuffix (click)="hide_password = !hide_password" [attr.aria-label]="'hide_password password'" [attr.aria-pressed]="hide_password">
                        <mat-icon>{{hide_password ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Telefono</mat-label>
                    <input #phone name="celular" matInput type="number" placeholder="964587299" minlength="9" maxlength="9" ngModel="{{ pickupService.runner.celular }}" required>
                    <span matTextPrefix>+56&nbsp;</span>
                    <mat-icon matSuffix>phone_iphone</mat-icon>
                </mat-form-field>
                <button mat-raised-button color="primary" class="btn-login" type="submit" [disabled]="form.invalid || phone.value.length < 9 || phone.value.length > 9">
                    Ingresar
                </button>

                <div *ngIf="loginService.badlogin" class="error">
                    Nombre de usuario o contraseña inválido
                </div>
                <button mat-raised-button class="btn-qr" type="button" (click)="changeIngresoState()">
                    <mat-icon>qr_code_2</mat-icon>&nbsp;Ingresar con QR
                </button>

              <button mat-raised-button class="btn-pass" type="button" (click)="resetPassword()">
                Cambiar contraseña
              </button>
            </div>
        </form>
    </main>
    <footer class="footer">

        <img id="logo" src="../../assets/images/ubq.png">
    </footer>
</div>
