import {Component, Inject,ViewChild} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { PickupService } from 'src/app/services/pickup.service';


@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss']
})
export class EncuestaComponent {

  public preguntas:any=[];

  email = new FormControl("");
  enviando:boolean=false;
  es_sod:boolean=false;
  es_catex:boolean=false;
  
  profileForm = new FormGroup({});

  mensaje_error="";
  mensaje_enviando="";


  p5:any='';
  p2_1:any='';

  constructor(public pickupService : PickupService,public dialogRef: MatDialogRef<EncuestaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      

    console.log('ENCUESTA :');
    console.log(data.preguntas);
    this.preguntas=data.preguntas;

  }


  exit(){
    this.dialogRef.close();
  }


  submit(encuesta:any){

    
    var _self=this;
    console.log(encuesta.form.value);
    if(encuesta.form.status=='VALID'){

        this.enviando = true;
        this.mensaje_enviando="Enviando encuesta...";
        this.mensaje_error="";
        var respuestas:any=[];  

        Object.entries(encuesta.form.value).forEach(([key,value])=>{

          respuestas.push({'pregunta':key,'respuesta':value});
          console.log(`${key}: ${value}`);

        });



        this.pickupService.guardarEncuesta(this.pickupService.usuario.id,respuestas).then(data=>{

            this.dialogRef.close();

        }).catch(function(e){

            _self.dialogRef.close();

        });
        


    }else{

      this.mensaje_error="Por favor, conteste todas las preguntas para continuar...";
    }
  }


  opcionSodCatex(opcion:any){

      if(opcion==1){ this.es_sod=true;  this.es_catex=false; }
      if(opcion==2){ this.es_sod=true; this.es_catex=true;  }
      console.log(opcion);
  }

  eval_group(pregunta:number){

    console.log(this.p2_1);

  }
}
