import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rechazobulto',
  templateUrl: './rechazobulto.component.html',
  styleUrls: ['./rechazobulto.component.scss']
})
export class RechazobultoComponent {

  bultos:any=[];


  constructor(private router: Router, public pickupService : PickupService,public http: HttpClient, public utilityService: UtilitiesService) { 


    this.utilityService.loadingScreen();
    this.http.post(environment.api_host+"/api/bultos-pendientes",{sg123:this.pickupService.pedidoActivo.sg123}).subscribe((data:any)=>{
        this.bultos = data;
        this.utilityService.dismissScreen();
        console.log(data);
    });




  }

  ngOnInit() {
  }

  async handleChange(e:any,id_bulto:any) {

    await this.utilityService.loadingScreen();
    console.log('ionChange fired with value: ' + id_bulto + ', ' + e.detail.value);

    this.http.post(environment.api_host+"/api/set_aceptacion_bulto",{id_motivo_rechazo:e.detail.value,id_bulto:id_bulto}).subscribe(data=>{

      this.utilityService.dismissScreen();
      console.log(data);
  },error=>{
      this.utilityService.dismissScreen();
  });
  }
}
