import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Socket } from "ngx-socket-io";
import * as moment from "moment";
import { catchError, retry, timeout } from "rxjs/operators";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "src/environments/environment.production";
import { UtilitiesService } from "./utilities.service";
import { lastValueFrom, Subject } from "rxjs";
import { Md5 } from "ts-md5";
import packagejson from '../../../package.json';
import { Runner } from "../interfaces/Runner";
import { Local } from "../interfaces/Local";
import { User } from "../interfaces/User";
import { Pedido } from "../interfaces/Pedido";
import { Bulto } from "../interfaces/Bulto";


@Injectable({
  providedIn: "root",
})
export class PickupService {

  public appVersion: string = packagejson.version;

  runner:Runner = { id: -1, nombre: '', rut: '', token: '', celular: ''};
  local:Local = {codigo: 100, nombre: 'Local 100'};

  online:boolean = true;
  grupoEncuesta:number = 0;

  usuario:User = {
    id: -1,
    logeado: 0,
    rut: '',
    nombre_usuario: '',
    celular: '',
    codigo_local: 0,
    id_tienda: 0,
    nombre_local: '',
    username:'',
    silenciado: 0
  };

  pedidoActivo:Pedido = {
    sg123: '',
    nombrecliente: '',
    status: '',
    estado_cliente: 0,
    runner: '',
    id_runner: 0,
    celular: '',
    rut: '',
    patente:'',
    tipo_entrega: '',
    imagen_firma: '',
    orden: '',
    fecharetiro: '',
    fecha: '',
    fecha_runner: '',
    tiempo: '',
    ventana: '',
    pin:'',
    tipo:'',
    segundos: 0,
    hora_llegada_estimada: '',
    eta_slug: '',
    status_runner: '',
    comentario_runner: '',
    ubicacion_bodega: '',
    modo_retiro: '',
    zona_entrega: '',
    rut_retira: '',
    patente_retira: '',
    ubicaciones: '',
    dp:'',
    email:'',
    notificacion_llegada: '',
    posicion_cliente: '',
    distancia:'',
    version: this.appVersion,
    bultos: [],
    productos:[],
    pago: false
  };

  codigoPosicion: string = '';
  // cola_firma:any = [];
  loading: boolean = false;
  programados_page:number = 1;
  programados_next:boolean = true;

  llamado_activado = 1;

  private updateEmitter:any = new Subject<string>();
  updateEmitter$:any= this.updateEmitter.asObservable();

  pedidosEsperando:Pedido[] = [];
  pedidosProgramados:Pedido[] = [];
  pedidosEnCamino:Pedido[] = [];
  pedidosPreparacion:Pedido[] = [];

  pedidosProgramadosFiltrados:Pedido[] =[];


  inputBuscar='';


  constructor(
    readonly swUpdate: SwUpdate,
    public http: HttpClient,
    public socket: Socket,
    private utilityService: UtilitiesService
  ) {
    this.swUpdate.checkForUpdate();
  }

  async syncronizarBD(from_login: number = 0, loading_screen: number = 0, next_page:boolean = false) {
    let token = localStorage.getItem('token');
    const headers = { 'Authorization': 'e8a7154cd311dd2fd4bfebe8506a0a805178c576','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'*' };

    this.utilityService.loadingLabel = 'Sincronizando...';

    if(next_page) this.programados_page++;

    try {

      let pedidos:any = await lastValueFrom(this.http.post(`${environment.api_host}/acuenta/api-app/pedidos-pwa-runner-preparados`,{
        token:token,
        local_codigo:this.usuario.codigo_local,
        username:this.usuario.username,
        pagina: this.programados_page
      },{headers}).pipe(retry(3),timeout(15000), catchError(async e => {
        throw Error('TimeOut');
      })));


      if(!pedidos.autorizado) return this.updateEmitter.next('LogOut');

      let pedidos_en_cola =  JSON.parse(await this.utilityService.getObject('cola_firma'));

      if(pedidos_en_cola.length){
        pedidos.pedidos_programados = pedidos.pedidos_programados.filter((pedido:Pedido) => (pedidos_en_cola.length ? !pedidos_en_cola.find((p:any) => p.orden === pedido.orden) : false));
        pedidos.pedidos_esperando = pedidos.pedidos_esperando.filter((pedido:Pedido) => (pedidos_en_cola.length ? !pedidos_en_cola.find((p:any) => p.orden === pedido.orden) : false ));
      }

      console.log(pedidos.pedidos_programados);

      pedidos.pedidos_programados.forEach((pedido:Pedido) => {
        pedido.bultos.forEach((bulto:any) => {
          if (bulto.en_carro == 1) bulto.en_carro = true;
          else bulto.en_carro = false;
        });
      });

      this.pedidosProgramados = pedidos.pedidos_programados;

      console.log(pedidos);

      let data:any = await lastValueFrom(this.http.post(environment.api_host+"/acuenta/api-app/get-pedidos-recepcion",{
        token:token,
        local_codigo:this.usuario.codigo_local,
        username:this.usuario.username
      },{headers}).pipe(retry(3),timeout(15000), catchError(async e => {
        throw Error('TimeOut');
      })));


      this.pedidosPreparacion = data.pedidos;

      this.utilityService.loadingLabel = '';

      this.filtrarPedidosrogramados();

      this.online = true;

    } catch (error) {
      console.error(error);
      this.utilityService.loadingLabel = '';
    }
  }

  async syncronizarEnEspera(){
    let token = localStorage.getItem('token');
    const headers = { 'Authorization': 'e8a7154cd311dd2fd4bfebe8506a0a805178c576','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'*' };
    this.utilityService.loadingLabel = 'Sincronizando...';

    let pedidos:any = await lastValueFrom(this.http.post(`${environment.api_host}/acuenta/api-app/pedidos-pwa-runner-esperando`,{
      token:token,
      local_codigo:this.usuario.codigo_local,
      username:this.usuario.username
    },{headers}).pipe(retry(3),timeout(15000), catchError(async e => {
      throw Error('TimeOut');
    })));

    this.utilityService.loadingLabel = '';

    // if(!pedidos.autorizado) return this.updateEmitter.next('LogOut');

    let pedidos_en_cola =  JSON.parse(await this.utilityService.getObject('cola_firma'));

    if(pedidos_en_cola.length){
      pedidos.pedidos_programados = pedidos.pedidos_programados.filter((pedido:Pedido) => (pedidos_en_cola.length ? !pedidos_en_cola.find((p:any) => p.orden === pedido.orden) : false));
      pedidos.pedidos_esperando = pedidos.pedidos_esperando.filter((pedido:Pedido) => (pedidos_en_cola.length ? !pedidos_en_cola.find((p:any) => p.orden === pedido.orden) : false));
    }

    pedidos.pedidos_esperando.forEach((pedido:Pedido) => {
      pedido.bultos.forEach((bulto:any) => {
        if (bulto.en_carro == 1) bulto.en_carro = true;
        else bulto.en_carro = false;
      });

      let _hora_llegada = moment(pedido.ts_llegada_totem,"YYYY-MM-DD HH:mm:ss");
      let _hora_actual = moment.utc();

      try {
        let duration = moment.duration(_hora_actual.diff(_hora_llegada));

        pedido.tiempo = this.utilityService.formatoSegundos(Math.round(duration.asSeconds()));
        pedido.segundos = duration.asSeconds();

        let diferencia_dias_ventana = (new Date().getDate()) - (new Date(pedido.ventana_desde ?? '').getDate());

        pedido.ventana = (diferencia_dias_ventana <= 0 ? pedido.ventana :
                        diferencia_dias_ventana == 1 ? `${pedido.ventana} (ayer)`:
                        diferencia_dias_ventana == 2 ? `${pedido.ventana} (anteayer)` : `${pedido.ventana} ${pedido.ventana_desde?.substring(0,10)}`);

        this.online = true;
      }catch(error){
        console.error(error);
      }
    });

    this.pedidosEsperando = pedidos.pedidos_esperando || [];
    this.pedidosEnCamino  = pedidos.pedidos_en_camino || [];

    this.pedidosEsperando.forEach((item:Pedido) => {
        this.pedidosProgramados = this.pedidosProgramados.filter((i:Pedido)=> i.sg123 !== item.sg123 );
    });

    this.pedidosEnCamino.forEach((item:Pedido) =>{
      this.pedidosProgramados = this.pedidosProgramados.filter((i:Pedido)=>i.sg123 !== item.sg123 );
    });


    this.filtrarPedidosrogramados();

  }

  async checkearBulto(bulto:Bulto){
    try {
      return true;

      let data = await (lastValueFrom(this.http.post(`${environment.api_host}/api/checkear-bulto`, {
        id_bulto: bulto.id,
        sg123: bulto.sg123,
        status: bulto.en_carro ? 1 : 0,
      })));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // async entregarPedidoFirma(item:Pedido) {
    // let fecha_disp = moment().format("YYYY-MM-DD HH:mm:ss");

    // if(!item.version) item.version = this.appVersion;

    // let params_firma = new HttpParams()
    //         .append("runner_id", this.usuario.id.toString())
    //         .append("orden", item.sg123)
    //         .append("fecha", item.fecha)
    //         .append("fecha_runner", item.fecha_runner)
    //         .append("fecha_disp", fecha_disp)
    //         .append("evidencia_entrega", item.evidencia_entrega ?? '')
    //         .append("rut_retira", item.rut_retira)
    //         .append("patente_retira", item.patente_retira)
    //         .append("tipo_entrega", item.tipo_entrega)
    //         .append("version_app", item.version);

    // console.log('entregarPedidoFirma', item);

    // this.cola_firma.push({item, key: 'cola_firma'});
    // this.http.post(environment.api_host+"/api/firmar-entrega-app-socket", params_firma).pipe(retry(3)).subscribe(data => {}, error => {
    //   console.error(error);
    // });

    // this.cola_firma.push({item, key: 'cola_firma'});
    // this.eliminarPedidoEsperando(item.sg123);
  // }

  // async entregarPedidoFoto(item:any){
    // let md5 = new Md5();
    // let encrypted_64base = md5.appendStr(item.imagen_firma).end()?.toString();

    // if(!item.version) item.version = this.appVersion;

    // let params_imagen = new HttpParams().append("orden", item.sg123).append("imagen_firma", item.imagen_firma);

    // this.http.post(environment.api_host+"/api/firmar-entrega-app-socket-imagen",params_imagen).pipe(retry(3)).subscribe(data => {
    //   if(data !== encrypted_64base) throw Error('Posible error al enviar la foto. Los MD5 no coinciden')
    // }, error => {
      // this.cola_firma.push({item, key: 'cola_imagen'});
  //     console.error(error);
  //   });
  // }

  eliminarPedidoEsperando(sg123:any) {
    console.log('ELIMINANDO PEDIDO : '+sg123)

    this.pedidosEsperando.splice(this.pedidosEsperando.findIndex((pedido:any) => pedido.sg123 === sg123), 1);

    this.utilityService.setObject("pedidos_esperando", this.pedidosEsperando);
  }

  async actualizarTiempoEspera() {

    this.pedidosEsperando.forEach((pedido:Pedido) => {
      let time_past = this.utilityService.getTimePastTill(pedido.ts_llegada_totem ?? '', false);

      let hora = Math.floor(time_past / 3600);
      let minutos = Math.floor((time_past % 3600) / 60);
      let segundos = Math.floor((time_past % 3600) % 60);

      pedido.tiempo = time_past > 0 ? `${this.utilityService.leadZero(hora)}:${this.utilityService.leadZero(minutos)}:${this.utilityService.leadZero(segundos)}` : '00:00:00';
      pedido.segundos = time_past;

    });
  }

  public actualizarLlegadaEstimada(){
    this.pedidosEnCamino.forEach((pedido:Pedido) => {
      let eta = this.utilityService.getTimePastTill(pedido.hora_llegada_estimada);

      let hora = Math.floor(eta / 3600);
      let minutos = Math.floor((eta % 3600) / 60);
      let segundos = Math.floor((eta % 3600) % 60);

      pedido.eta = eta > 1 ? `${this.utilityService.leadZero(hora)}:${this.utilityService.leadZero(minutos)}:${this.utilityService.leadZero(segundos)}` : 'Por llegar';
    });
  }

  async editarComentarioRunner(sg:string, comentario:string){
    try {
      let data = await (lastValueFrom(this.http.post(`${environment.api_host}/api/comentario-runner-v2`, {
        sg123: sg,
        comentario: comentario
      })));

      console.log(data);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getPedidosProgramados(indice:number){
    try {
      return (await this.http.get(`url/?indice=${indice}`).toPromise());
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  async reagendarPedido(dia:string, hora:string) : Promise<Boolean>{
    try {
      let data = await (lastValueFrom(this.http.post(`${environment.api_host}/api/reagendapedido`, {
        fecha: dia,
        ventana: hora,
        sg123: this.pedidoActivo.sg123
      })));

      console.log(data)
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }

  }

  public filtrarPedidosrogramados(){
    if(this.inputBuscar.length>=4){

      this.inputBuscar = this.inputBuscar.toLocaleLowerCase();
      this.pedidosProgramadosFiltrados = this.pedidosProgramados.filter((item:any) => {
        return item.sg123.includes(this.inputBuscar) ||
                item.nombrecliente.toLowerCase().includes(this.inputBuscar)  ||
                item.email.toLowerCase().includes(this.inputBuscar) ||
                item.orden.toLowerCase().includes(this.inputBuscar) ||
                item.tipo.toLowerCase().includes(this.inputBuscar)
      });
    }else{
      this.pedidosProgramadosFiltrados=this.pedidosProgramados;
    }

  }

  public async getBultosPendientes() : Promise<{bultos: Bulto[], status:boolean}>{
    try {
      const data:Bulto[] = await lastValueFrom(this.http.post<Bulto[]>(environment.api_host+"/api/bultos-pendientes",{sg123:this.pedidoActivo.sg123}));
      return {bultos: data, status: true};
    } catch (error) {
      console.error(error);
      return {bultos: [], status: false};
    }
  }

  public async resetBultoPosition(id:any) : Promise<{bultos: Bulto[], status:boolean}>{
    try {
      const data:Bulto[] = await lastValueFrom(this.http.post<Bulto[]>(environment.api_host+"/api/bultos-reset-ubicacion",{sg123:this.pedidoActivo.sg123,id:id}));
      return {bultos: data, status: true};
    } catch (error) {
      console.error(error);
      return {bultos: [], status: false};
    }
  }

  public async asignarUbicacionBultos(bultos_noasignados:Bulto[]) : Promise<{bultos: Bulto[], status:boolean}>{
    try {
      const data:Bulto[] = await lastValueFrom(this.http.post<Bulto[]>(environment.api_host+"/api/bultos-registrar-ubicaciones",{
        sg123:this.pedidoActivo.sg123,
        bultos:bultos_noasignados,
        ubicacion:this.codigoPosicion
      }));

      return {bultos: data, status: true};
    } catch (error) {
      console.error(error);
      return {bultos: [], status: false};
    }
  }

  public async actualizarUbicacionBultos() : Promise<{bultos: Bulto[], status:boolean}>{
    try {
      const data:Bulto[] = await lastValueFrom(this.http.post<Bulto[]>(environment.api_host+"/api/actualizar-ubicacion-recepcion",{
        sg123:this.pedidoActivo.sg123,
        productos:this.pedidoActivo.productos
      }));
      return {bultos: data, status: true};
    } catch (error) {
      console.error(error);
      return {bultos: [], status: false};
    }
  }


  public async getEncuesta(){

    let runner_id = this.usuario.id;
    try {
      return (await this.http.post(environment.api_host+"/api-app/encuesta",{runner_id:runner_id}).toPromise());
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  public async guardarEncuesta(runner_id:any,respuestas:any){
    try {
      return (await this.http.post(environment.api_host+"/api-app/encuesta/respuesta",{runner_id:runner_id,respuestas:respuestas}).toPromise());
    } catch (error) {
      console.error(error);
      return null;
    }
  }



  public async logLlamarCliente(log_info:{telefono:string,pedido:string,entrante:number,id_usuario:number,codigo_local:number}) : Promise<boolean>{
    try {
      const data:any = await lastValueFrom(this.http.post<any>(environment.api_host+"/api/log-llamar-cliente",{
        telefono: log_info.telefono,
        sg123: log_info.pedido,
        entrante: log_info.entrante,
        id_usuario: log_info.id_usuario,
        codigo_local: log_info.codigo_local
      }));
      console.log(data);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

}
