<mat-toolbar class="toolbar" style="height: 65px;">
    <div class="left">
        <button mat-icon-button (click)="switchDrawer()">
            <mat-icon>menu</mat-icon>
        </button>
        <img src="assets/images/logo_color.svg" class="logo"/>
    </div>
    <div class="right">
        <span>{{ usuario }}<br>
        </span>
        <span>{{ local }}&nbsp;( Loc : {{ provService.usuario.codigo_local}})</span>
        <span *ngIf="provService.online"><mat-icon fontIcon="wifi" class="icon"></mat-icon>online</span>
        <span class="off" *ngIf="!provService.online"><mat-icon fontIcon="wifi_off" class="icon"></mat-icon>offline</span>
    </div>
</mat-toolbar>