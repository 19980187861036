import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Bulto } from 'src/app/interfaces/Bulto';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-picker-manual-bultos',
  templateUrl: './picker-manual-bultos.component.html',
  styleUrls: ['./picker-manual-bultos.component.scss']
})
export class PickerManualBultosComponent {

  bultos:Bulto[] = [];
  bultos_seleccionados:Bulto[] = [];
  bultos_asignados:Bulto[] = [];
  bultos_noasignados:Bulto[] = [];

  constructor(private router: Router,public pickupService : PickupService, private utilityService : UtilitiesService) {}

  async ngOnInit(){
    let data:{bultos: Bulto[], status:boolean} = await this.pickupService.getBultosPendientes();

    if(!data.status) return this.utilityService.showSnackBar("Ha ocurrido un error inesperado, vuelva a ingresar en unos segundos.", "#c22");

    this.loadBultosList(data.bultos);
  }

  loadBultosList(bultos:Bulto[]){
    this.bultos_asignados = [];
    this.bultos_noasignados = [];

    this.bultos = bultos.map((bulto:Bulto) => {

      if(bulto.ubicacion) this.bultos_asignados.push(bulto);
      else this.bultos_noasignados.push(bulto);

      return bulto;
    });

  }


  async resetUbicacion(id:any){

    let data:{bultos: Bulto[], status:boolean} = await this.pickupService.resetBultoPosition(id);

    if(!data.status) return this.utilityService.showSnackBar("Ha ocurrido un error inesperado, vuelva a intentarlo en unos segundos.", "#c22");

    this.pickupService.pedidoActivo.bultos = data.bultos;

    if(this.pickupService.pedidoActivo.tipo == 'BT'){

      let result = await this.pickupService.actualizarUbicacionBultos();

      if(!result.status) return this.utilityService.showSnackBar("Ha ocurrido un error inesperado, favor contactar con soporte.", "#c22");

    }

    this.loadBultosList(data.bultos);
  }

  async asignarUbicaciones(){

    let data:{bultos: Bulto[], status:boolean} = await this.pickupService.asignarUbicacionBultos(this.bultos_noasignados);

    if(!data.status) return this.utilityService.showSnackBar("Ha ocurrido un error inesperado, vuelva a intentarlo en unos segundos.", "#c22");

    this.pickupService.pedidoActivo.bultos = data.bultos;

    if(this.pickupService.pedidoActivo.tipo == 'BT'){

      let result = await this.pickupService.actualizarUbicacionBultos();

      if(!result.status) return this.utilityService.showSnackBar("Ha ocurrido un error inesperado, es probable que no se le notifique al usuario, favor contactar con soporte.", "#c22");

    }

    this.loadBultosList(data.bultos);

  }

  seleccionarBulto(id:any){
    console.log(id);
  }


  salir(){
    this.router.navigate(['/picker-manual']);
  }

}