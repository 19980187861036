<mat-card class="order-card">
  <mat-card-header>
    <mat-card-title>Productos en el pedido:</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-product-item
      *ngFor="let product of products; let i = index; let last = last"
      [product]="product"
      [showBorder]="!last"
    ></app-product-item>
  </mat-card-content>
</mat-card>
