<section>
    <div>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Reagendar pedido</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              <div class="dias">
                <button class="button-dia" (click)="selectDay($event, dia)" *ngFor="let dia of dias">{{dia}}</button>
              </div>
              <div class="horas">
                <mat-form-field class="example-full-width">
                  <mat-label>Hora</mat-label>
                  <mat-select placeholder="Seleccione hora"  [(ngModel)]="hora">
                    <mat-option value="09:00-11:00">09:00-11:00</mat-option>
                    <mat-option value="11:00-13:00">11:00-13:00</mat-option>
                    <mat-option value="13:00-15:00">13:00-15:00</mat-option>
                    <mat-option value="15:00-17:00">15:00-17:00</mat-option>
                    <mat-option value="17:00-19:00">17:00-19:00</mat-option>
                    <mat-option value="17:00-19:00">19:00-21:00</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-card-content>
            <mat-card-footer>
                <button
                  mat-raised-button
                  class="button btnlarge"
                  [disabled]="!dia || !hora"
                  (click)="reagendar()"
                >
                <mat-icon>edit_calendar</mat-icon> REAGENDAR PEDIDO
                </button>
            </mat-card-footer>
        </mat-card>
    </div>
    <div class="exit">
      <button mat-raised-button color="primary" class="button btnlarge" (click)="volver()" >Volver al Pedido</button>
    </div>
</section>