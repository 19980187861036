import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-picker-manual-prep',
  templateUrl: './picker-manual-prep.component.html',
  styleUrls: ['./picker-manual-prep.component.scss']
})
export class PickerManualPrepComponent {

  c1:string='BT';
  c2:string='88';
  c3:string='1';
  c4:string='1';


  constructor(private router: Router,public pickupService : PickupService, public utilityService: UtilitiesService) { }

  async ngOnInit() {

    if(!this.pickupService.pedidoActivo.sg123){
      this.pickupService.pedidoActivo = JSON.parse(await this.utilityService.getObject('pedidoActivo'));
    }
  }

  seleccionar(){
    this.pickupService.codigoPosicion = this.c1+'-'+this.c2+'-'+this.c3+'-'+this.c4;
    console.log(this.pickupService.codigoPosicion);
    this.router.navigate(['/picker-manual-bultos-prep']);
  }
}
