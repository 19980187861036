<mat-card>
    <mat-card-header>
        <mat-card-title>Ingrese información de cliente que retira </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <img src="assets/images/check.png" style="width:60px"/>
        <h4>Pedido entregado</h4>
        <h5>exitosamente</h5>

        <form class="example-form">
            <mat-form-field class="example-full-width">
            <mat-label>RUT</mat-label>
            <input matInput  placeholder="Ingrese RUT..." [(ngModel)]="rut_retira" value=" "  maxlength="10">
            </mat-form-field>
            <mat-form-field class="example-full-width">
            <mat-label>Patente</mat-label>
            <input matInput placeholder="Ingrese PATENTE..." maxlength="6"  [(ngModel)]="patente_retira" value=" ">
            </mat-form-field> 
            <mat-form-field class="example-full-width">
            <mat-label>Comentario</mat-label>
            <textarea matInput placeholder=" "  rows="6" cols="20"  [(ngModel)]="comentario"></textarea>
            </mat-form-field>

        </form>
    </mat-card-content>
    <mat-card-footer>
        <button mat-raised-button color="primary" class="button"   (click)="guardar()" > FINALIZAR</button>
    </mat-card-footer>
</mat-card>


