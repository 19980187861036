import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { SocketService } from 'src/app/services/socket.service';

@Component({
  selector: 'app-llegadacliente',
  templateUrl: './llegadacliente.component.html',
  styleUrls: ['./llegadacliente.component.scss']
})
export class LlegadaclienteComponent  implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public pickupService : PickupService, private socketService : SocketService) { }

  ngOnInit() {}

  goOpciones(item:any){
    this.pickupService.pedidoActivo = item;
    this.router.navigate(['/opciones']);
  }

  goCliente(){
    this.socketService.stopAudio();
    this.router.navigate(['']);
  }



}
