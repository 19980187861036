import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ventana-confirmacion',
  templateUrl: './ventana-confirmacion.component.html',
  styleUrls: ['./ventana-confirmacion.component.scss']
})
export class VentanaConfirmacionComponent {

  constructor(public dialogRef: MatDialogRef<VentanaConfirmacionComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(data);
  }

  exit(response:boolean){
    this.dialogRef.close(response);
  }

}
