<div *ngIf="hide_camera && !confirm">
    <h2 mat-dialog-title>
        <mat-icon>notification_important</mat-icon> 
        Atención
    </h2>
    <div mat-dialog-content>
        <ul class="corrImg">
            <li>
                <img src="../../../../assets/images/incorrecto0.png" />
            </li>
            <li>
                <img src="../../../../assets/images/correcto1.png" />
            </li>
        </ul>
        <p class="disclaimer">En la foto debe aparecer claramente los  <strong>bultos</strong> y el <strong>automóvil</strong> del cliente</p>
    </div>
    <div mat-dialog-actions>
        <button [disabled]="camera_permission_error || camera_system_error" mat-raised-button color="primary" class="btnlarge" (click)="proceed()">Siguiente</button>
        <button mat-raised-button color="link" class="btnlarge" (click)="volver()"> <mat-icon>arrow_back</mat-icon>Volver a las opciones</button>
    </div>
</div>
<div class="photoshot" *ngIf="!hide_camera && !confirm">
    <p>Saca una foto a los bultos del cliente</p>
    <webcam [trigger]="$trigger" [width]="350" [height]="400" (imageCapture)="handleImage($event)"></webcam>
    <button mat-raised-button color="primary" class="btnlarge" (click)="takePhoto()">Tomar foto</button> 
    <button mat-raised-button color="link" class="btnlarge" (click)="volver()" > <mat-icon>arrow_back</mat-icon>Volver a opciones</button>
</div>
<div class="confirm" *ngIf="!hide_camera && confirm">
    <p>¿Firmar el pedido con esta foto?</p>
    <img [src]="preview_image" [width]="350" [height]="400">
    <div class="buttons">
        <button  mat-raised-button class="bg-danger btnlarge"  (click)="retry()">Reintentar</button>
        <button  mat-raised-button class="bg-success btnlarge"  (click)="firmar()">Aceptar foto</button>
        <button mat-raised-button color="link" class="btnlarge" (click)="volver()" > <mat-icon>arrow_back</mat-icon>Descartar y volver</button>
    </div>
</div>