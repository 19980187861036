import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.production';


@Component({
  selector: 'app-sidenav-content',
  templateUrl: './sidenav-content.component.html',
  styleUrls: ['./sidenav-content.component.scss']
})
export class SidenavContentComponent {


  llamado_activado:number=1;
  constructor(private router : Router, public pickupService : PickupService, private utilityService : UtilitiesService, private loginService : LoginService,public http: HttpClient) {


  }


  ngOnInit(){

    var usr:any = localStorage.getItem('usuario');

    if(usr){
    usr = JSON.parse(usr);

    var silenciado = parseInt(usr.silenciado);

    console.log('SILECIADO : '+silenciado);

    if(silenciado==1){
      this.llamado_activado = 0;



    }else
      this.llamado_activado = 1; 

  }
   
  
  }

  gotoInicio(){
    this.utilityService.changeDrawerState(false);
    this.router.navigate(['/inicio']);
  }

  gotoBuscar(){
    this.utilityService.changeDrawerState(false);
    this.router.navigate(['/buscar']);
  }

  gotoColaEnvio(){
    this.utilityService.changeDrawerState(false);
    this.router.navigate(['/colaenvio']);
  }

  gotoLogin(){
    this.utilityService.changeDrawerState(false);
    this.loginService.logout();
  }

  gotoRecepcionBT(){
    this.utilityService.changeDrawerState(false);
    this.router.navigate(['/recepcion-bt']);
  }

  setSilenciado(){
    
    var _silenciado=1;
    if(this.pickupService.llamado_activado){
      _silenciado=0;
    }

    var usr:any = localStorage.getItem('usuario');
    usr = JSON.parse(usr);

    let url = environment.api_host+"/api/dispositivo/silenciartelefono";
    this.http.post(url,{user_id:usr.id, silenciado:_silenciado}).subscribe(data=>{
        console.log(data);
    });


  }


  goSoporte(){
    location.href = "mailto:email-address@gmail.com?subject='I need help'&body='write some message'";
 }
}
