<section>
    <div class="information">
        <mat-card>
            <mat-card-header [ngClass]="{'success' : pickupService.pedidoActivo.segundos <= 180, 'warning' : pickupService.pedidoActivo.segundos > 180 && pickupService.pedidoActivo.segundos <= 300 , 'danger' : pickupService.pedidoActivo.segundos > 300}">
                <img *ngIf="pickupService.pedidoActivo.tipo=='food'" class="icontipo" src="assets/images/sod.png">
                <img *ngIf="pickupService.pedidoActivo.tipo=='BT'" class="icontipo" src="assets/images/babytuto.png">
                <img *ngIf="pickupService.pedidoActivo.tipo=='Catex'" class="icontipo" src="assets/images/catex.png">
                <mat-card-title>{{pickupService.pedidoActivo.nombrecliente}}</mat-card-title>
                <mat-card-subtitle>{{pickupService.pedidoActivo.sg123}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="information">
                <table>
                    <tr>
                        <td><mat-icon>local_play</mat-icon></td>
                        <td>Orden</td>
                        <td>{{ pickupService.pedidoActivo.orden }}</td>
                    </tr>
                    <tr>
                        <td><mat-icon>person</mat-icon></td>
                        <td>Email</td>
                        <td>{{ pickupService.pedidoActivo.email }}</td>
                    </tr>
                    <tr>
                        <td><mat-icon>person</mat-icon></td>
                        <td>DP</td>
                        <td>{{ pickupService.pedidoActivo.dp }}</td>
                    </tr>
                    <tr class="reagendar-container">
                        <td><mat-icon>event_available</mat-icon></td>
                        <td>Fecha retiro</td>
                        <td>
                            <p>
                                {{ pickupService.pedidoActivo.fecharetiro }}
                            </p>
                          <!--
                            <span>
                                <button mat-raised-button (click)="reagendar()">
                                    Reagendar

                                </button>
                            </span>-->
                        </td>
                    </tr>
                    <tr>
                        <td><mat-icon>access_time</mat-icon></td>
                        <td>Ventana</td>
                        <td>
                            <span>{{ pickupService.pedidoActivo.ventana.split(' ')[0] }}<br></span>
                            <span>{{ pickupService.pedidoActivo.ventana.split(' ')[1] }}</span>
                        </td>
                    </tr>
                    <tr *ngIf="pickupService.pedidoActivo.status === 'Cliente esperando'">
                        <td><mat-icon>timer</mat-icon></td>
                        <td>Tiempo de espera</td>
                        <td>{{ pickupService.pedidoActivo.tiempo }}</td>
                    </tr>
                    <tr *ngIf="pickupService.pedidoActivo.status === 'Compra aceptada' && pickupService.pedidoActivo.hora_llegada_estimada">
                        <td><mat-icon>business_center</mat-icon></td>
                        <td>Llega en</td>
                        <td>{{ pickupService.pedidoActivo.eta }}</td>
                    </tr>
                    <tr *ngIf="pickupService.pedidoActivo.id_runner>0">
                        <td><mat-icon>directions_run</mat-icon></td>
                        <td>Runner asignado</td>
                        <td>{{ pickupService.pedidoActivo.runner }}</td>
                    </tr>
                    <tr *ngIf="pickupService.pedidoActivo.status=='Cliente esperando'">
                        <td><mat-icon>person_pin_circle</mat-icon></td>
                        <td>Ubicación cliente</td>
                        <td>{{ pickupService.pedidoActivo.zona_entrega }}</td>
                    </tr>
                    <tr *ngIf="pickupService.pedidoActivo.ubicacion_bodega!='' && pickupService.pedidoActivo.ubicacion_bodega">
                        <td><mat-icon>location_on</mat-icon></td>
                        <td>Ubicación bodega</td>
                        <td>{{ pickupService.pedidoActivo.ubicacion_bodega }}</td>
                    </tr>
                    <tr *ngIf="pickupService.pedidoActivo.modo_retiro">
                        <td><mat-icon>store_mall_directory</mat-icon></td>
                        <td>Modo retiro</td>
                        <td>{{ pickupService.pedidoActivo.modo_retiro }}</td>
                    </tr>
                    <tr>
                        <td>
                            <mat-icon>monetization_on</mat-icon>
                        </td>
                        <td>Pago</td>
                        <td>
                            <span *ngIf="pickupService.pedidoActivo.pago; else pendiente" class="badge badge-success">Pagado</span>
                            <ng-template #pendiente>
                                <span class="badge badge-warning">Pendiente</span>
                            </ng-template>
                        </td>
                    </tr>
                </table>


            </mat-card-content>
            <mat-card-footer>
                <table>
                    <tr class="comentario-container">
                        <td><mat-icon>insert_comment</mat-icon></td>
                        <td>Comentario</td>
                        <td>
                            {{ utilityService.stringReducerResume(pickupService.pedidoActivo.comentario_runner, 20) || 'Sin comentario' }}
                            <span>
                                <button mat-fab color="primary" (click)="goComentarios()">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </span>
                        </td>
                    </tr>
                </table>
            </mat-card-footer>
        </mat-card>
    </div>
    <div class="buttons">
        <button mat-raised-button color="primary" class="button btnlarge" (click)="OpcionesDeEntrega()">Entregar pedido</button>
        <button *ngIf="pickupService.pedidoActivo.status=='Cliente esperando'" mat-raised-button class="bg-warning button btnlarge" (click)="presentAlertConfirm()">Cliente no ha llegado</button>
        <a (click)="logLlamarCliente(pickupService.pedidoActivo.celular)" mat-raised-button class="bg-success button btnlarge" href="tel:+{{ pickupService.pedidoActivo.celular }}">Llamar al cliente</a>
        <button mat-raised-button color="link" class="btnlarge" (click)="volverListado()" > <mat-icon>arrow_back</mat-icon>Volver al listado</button>
    </div>
    <div class="bultos-container">
        <mat-card>
            <mat-card-header class="bultos-header">
                <span>Bultos Checkeados</span>
                <span>* Verifique que le entregará todos los bultos al cliente. Marque uno por uno.</span>
            </mat-card-header>
            <mat-card-content [ngClass]="{'checked': all_bultos_check}">
                <div class="bulto-item" *ngFor="let bulto of bultos">
                    <div>
                        <mat-icon>shopping_bag</mat-icon>
                        <p>{{bulto.id}}</p>
                    </div>
                    <p><b>{{bulto.ubicacion || '- Sin Ubicación - '}}</b> <br> <i>{{ bulto.descripcion}}</i></p>
                    <mat-checkbox color="primary" (change)="changeBultoState($event, bulto)" [checked]="+bulto.en_carro ? true : false"></mat-checkbox>
                </div>
                <div class="aviso_runner" *ngIf="all_bultos_check">
                    <p>Todos los bultos han sido checkeados</p>
                    <button mat-raised-button class="bg-warning button btnlarge" (click)="avisarLlegadaRunner()">LLEGUE A ZONA DE ENTREGA</button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
  <app-product-showcase [products]="pickupService.pedidoActivo.productos"></app-product-showcase>
</section>
