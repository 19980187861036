<h2 mat-dialog-title>
    <mat-icon>check_circle_outline</mat-icon>
    Ingrese información de cliente que retira
</h2>
<div mat-dialog-content class="center">
    <mat-icon class="large">check_circle_outline</mat-icon>
    <p>Pedido entregado exitosamente</p>
</div>
<div mat-dialog-actions>
    <button  mat-raised-button color="primary" (click)="close()">Finalizar</button>
</div>