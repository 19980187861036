<section>
    <div>
        <h3 class="sectionTitle">
            Seleccione bultos para ubicación&nbsp;<strong>{{ pickupService.codigoPosicion }}</strong>
        </h3>
        <p *ngIf="bultos_noasignados.length == 0" class="alert alert-nocontent">
            No hay bultos por asignar
        </p>
        <div>
            <mat-card *ngIf="bultos_noasignados.length > 0" >
                <mat-card-content>
                    <table class="table-noasignados">
                        <tr *ngFor="let bulto of bultos_noasignados">
                            <td><mat-icon>shopping_bag</mat-icon>{{ bulto.id }}</td>
                            <td>
                                <mat-checkbox color="primary" [(ngModel)]="bulto.isChecked"></mat-checkbox>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
                <mat-card-footer class="actionButtons">
                    <button mat-raised-button color="primary" class="button btnlarge" (click)="asignarUbicaciones()"  *ngIf="bultos_noasignados.length > 0" >
                        Asignar bultos a:&nbsp;<strong>{{ pickupService.codigoPosicion }}</strong>
                    </button>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
    <div>
        <h3 class="sectionTitle">
            Bultos asignados
        </h3>
        <p *ngIf="bultos_asignados.length==0" class="alert alert-nocontent">
            No hay bultos asignados
        </p>
        <div>
            <mat-card  *ngIf="bultos_asignados.length > 0">
                <mat-card-content>
                    <table class="table-asignados">
                        <tr *ngFor="let bulto of bultos_asignados">
                            <td> <mat-icon>shopping_bag</mat-icon> {{ bulto.id }}</td>
                            <td>{{ bulto.ubicacion }}</td>
                            <button mat-raised-button color="warn" class="button" (click)="resetUbicacion(bulto.id)" *ngIf="bultos_asignados.length > 0">
                                Quitar
                            </button>
                        </tr>
                    </table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="exit">
        <button mat-raised-button color="primary" class="button btnlarge" (click)="salir()" >Volver al Pedido</button>
    </div>
</section>