import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { QueueService } from 'src/app/services/queue.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-comentario',
  templateUrl: './comentario.component.html',
  styleUrls: ['./comentario.component.scss']
})
export class ComentarioComponent {

  @ViewChild('comentario') comentario: ElementRef | undefined;

  edit_mode:boolean = false;
  entrega:boolean = false;

  constructor(
    public pickupService: PickupService,
    public queueService : QueueService,
    public utilityService:UtilitiesService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((queryParams) => {
      const { tipo_entrada } = queryParams;

      if(tipo_entrada === 'editar'){
        this.edit_mode = true;
      }else{
        this.edit_mode = false;
        this.entrega = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.loadComments();
  }

  loadComments(){
    if(this.edit_mode){
      const input_comentario = this.comentario?.nativeElement;

      input_comentario.value = this.pickupService.pedidoActivo.comentario_runner;
    }
  }

  async editComment(){
    const input_comentario = this.comentario?.nativeElement;

    if((await this.pickupService.editarComentarioRunner(this.pickupService.pedidoActivo.sg123, input_comentario.value))){

      this.pickupService.pedidoActivo.comentario_runner = input_comentario.value;

      this.salir();

    }else{
      this.utilityService.showSnackBar('Error al editar comentario. Intente nuevamente');
    }
  }

  comentar():void{
    this.edit_mode = true;
    setTimeout(() => {
      this.loadComments();

    }, 250);
  }

  reintentar(){
    this.pickupService.pedidoActivo.loader_entrega = 'entregando';

    // let shallow_pedido = {...this.pickupService.pedidoActivo};

    // this.queueService.agregarColaLoop(shallow_pedido);

    this.queueService.agregarColaLoop(this.pickupService.pedidoActivo);
  }

  salir():void{
    if(!this.entrega && this.edit_mode) this.router.navigate(['/opciones']);
    else this.router.navigate(['/inicio']);
  }
}
