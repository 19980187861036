import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';


@Component({
  selector: 'app-compras-preparacion',
  templateUrl: './compras-preparacion.component.html',
  styleUrls: ['./compras-preparacion.component.scss']
})
export class ComprasPreparacionComponent implements OnInit  {

  pedidosPreparacion:any;
  paramBuscar:any;
  num_encontrados:number = 0;

  constructor(private router: Router,public pickupService : PickupService, public utilityService: UtilitiesService) {}

  ngOnInit() {
    this.pickupService.syncronizarBD();
  }

  goPicker(item:any){
    this.pickupService.pedidoActivo = item;
    sessionStorage.setItem('pedidoActivo', JSON.stringify(item));
    this.router.navigate(['/picker-manual-prep']);
  }

  buscarPedido(event: any){

    console.log(this.paramBuscar);

  }

  cumpleCriterioBusqueda(item:any){

    if(this.paramBuscar){

      if(item.sg123.toLowerCase().includes(this.paramBuscar.toLowerCase())
        || item.nombrecliente.toLowerCase().includes(this.paramBuscar.toLowerCase())
        || item.rut.toLowerCase().includes(this.paramBuscar.toLowerCase())
        ){
        return true
      }
      else{
          return false;
      }
    }
    return true;
  }
}
