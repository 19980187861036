<mat-drawer-container class="container" hasBackdrop>
  <mat-drawer #drawer class="sidenav" mode="over">
    <app-sidenav-content></app-sidenav-content>
  </mat-drawer>
  <app-header-toolbar [usuario]="pickupService.usuario.nombre_usuario" [local]="pickupService.usuario.nombre_local" *ngIf="loginService.logeado"></app-header-toolbar>
  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <div class="version">
    <p style="text-align: left; color:gray">
      Version {{ appVersion}}
    </p>
  </div>
</mat-drawer-container>