import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-cambiopassword',
  templateUrl: './cambiopassword.component.html',
  styleUrls: ['./cambiopassword.component.scss']
})
export class CambiopasswordComponent {

  hide_password:boolean=true;
  hide_repeat_password:boolean=true;
  error:boolean=false;

  constructor(
    public dialogRef: MatDialogRef<CambiopasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public loginService: LoginService
    ) {
  }

  checkErrors(password:string, repeat_password:string, error_type:string){

    switch(error_type){
      case 'min_8': return this.atLeast8(password);
      case 'capital_letter': return this.capitalLetter(password);
      case 'lowercase_letter': return this.lowercaseLetter(password);
      case 'number': return this.number(password);
      case 'special_character': return this.specialCharacter(password);
      case 'repeat_password': return this.repeatPassword(password, repeat_password);
      case 'all': return this.checkAll(password, repeat_password);
      default: return false;
    }
  }

  atLeast8(password:string){
    return password.length < 8;
  }

  capitalLetter(password:string){
    return !/[A-Z]/.test(password);
  }

  lowercaseLetter(password:string){
    return !/[a-z]/.test(password);
  }

  number(password:string){
    return !/[0-9]/.test(password);
  }

  specialCharacter(password:string){
    return !/[^A-Za-z0-9]/.test(password);
  }

  repeatPassword(password:string, repeat_password:string){
    return (password != repeat_password) || (password.length < 1 || repeat_password.length < 1);
  }

  checkAll(password:string, repeat_password:string){
    return this.atLeast8(password)
    || this.capitalLetter(password)
    || this.lowercaseLetter(password)
    || this.number(password)
    || this.specialCharacter(password)
    || this.repeatPassword(password, repeat_password);
  }

  async changePassword(password:string, repeat_password:string){

    this.error = false;

    let {cambio_password, id, username} = this.data?.usuario ?? {cambio_password: false, id: false, username: false};

    if(!cambio_password || !id || !username) return console.error('No se ha encontrado ningun usuario.');

    if(this.checkErrors(password, repeat_password, 'all')) return console.error('Se intento ejecutar la función sin cumplir los requisitos');

    const response:{status:boolean, data:any} = await this.loginService.changePassword(password, id, this.data?.password);

    if(response.status){
      if(response.data.status){
        this.dialogRef.close({status: true, new_password: password});
      }else{
        this.error = true;
      }
    }
  }

  exit(){
    this.dialogRef.close({status: false});
  }
}