import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-puertas',
  templateUrl: './puertas.component.html',
  styleUrls: ['./puertas.component.scss']
})
export class PuertasComponent {

  barreras:any={};
  constructor(public http: HttpClient,private router: Router, public pickupService : PickupService, public utilityService : UtilitiesService) {

  }

  ngOnInit() {

    console.log(this.pickupService.usuario);

      const headers = { 'Authorization': 'e8a7154cd311dd2fd4bfebe8506a0a805178c576','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'*' };
      const body = { local_codigo : this.pickupService.usuario.codigo_local };

      this.http.post(environment.api_host+'/api/barreras-pickup',body,{headers}).subscribe((barreras:any)=>{
        this.barreras = barreras;
        console.log(barreras);
      });

  }

  ejecutarBarrera(id:any){

    const headers = { 'Authorization': 'e8a7154cd311dd2fd4bfebe8506a0a805178c576','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'*' };
    const body = { id : id, local_codigo: this.pickupService.usuario.codigo_local };
 
    this.http.post(environment.api_host+'/api/barrera-abrir-pickup',body,{headers}).subscribe((barreras:any)=>{
      this.barreras = barreras;
    
    });


  }

}