import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-menumodulo',
  templateUrl: './menumodulo.component.html',
  styleUrls: ['./menumodulo.component.scss']
})
export class MenumoduloComponent  implements OnInit {

  constructor(private router: Router, public pickupService : PickupService, private utilityService : UtilitiesService) {}

  ngOnInit() {}

}
