<section>
    <div class="pedido">
        <mat-card>
            <mat-card-header>
                <span style="color:white"><b>{{ pickupService.pedidoActivo.tipo }}</b></span>
                <mat-card-title>{{pickupService.pedidoActivo.nombrecliente}}</mat-card-title>
                <mat-card-subtitle>{{pickupService.pedidoActivo.sg123}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="information">
                <table>
                    <tr>
                        <td><mat-icon>local_play</mat-icon></td>
                        <td>Orden</td>
                        <td>{{ pickupService.pedidoActivo.orden }}</td>
                    </tr>
                    <tr>
                        <td><mat-icon>person</mat-icon></td>
                        <td>RUT</td>
                        <td>{{ pickupService.pedidoActivo.rut }}</td>
                    </tr>
                    <tr class="reagendar-container">
                        <td><mat-icon>event_available</mat-icon></td>
                        <td>Fecha retiro</td>
                        <td>
                            {{ pickupService.pedidoActivo.fecharetiro }}
                        </td>
                    </tr>
                    <tr>
                        <td><mat-icon>access_time</mat-icon></td>
                        <td>Ventana</td>
                        <td>
                            <span>{{ pickupService.pedidoActivo.ventana.split(' ')[0] }}<br></span>
                            <span>{{ pickupService.pedidoActivo.ventana.split(' ')[1] }}</span>
                        </td>
                    </tr>
                    <tr class="comentario">
                        <td><mat-icon>insert_comment</mat-icon></td>
                        <td>Comentario</td>
                        <td>{{ utilityService.stringReducerResume(pickupService.pedidoActivo.comentario_runner, 20) || 'Sin comentario' }}</td>
                    </tr>
                </table>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="ubicacion">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Seleccione ubicacion</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill">
                    <mat-select [(ngModel)]="c1">
                        <mat-option *ngFor="let pos of c1_pos" value="{{pos}}">{{pos}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-select  [(ngModel)]="c2">
                        <mat-option *ngFor="let pos of c2_pos" value="{{pos}}">{{pos}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-select  [(ngModel)]="c3">
                        <mat-option *ngFor="let pos of c3_pos" value="{{pos}}">{{pos}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill">
                <mat-select   [(ngModel)]="c4">
                    <mat-option *ngFor="let pos of c4_pos" value="{{pos}}">{{pos}}</mat-option>
                </mat-select>
            </mat-form-field>
            </mat-card-content>
            <mat-card-footer class="actionButtons">
                <button mat-raised-button color="primary" class="button btnlarge" (click)="seleccionar()"> Seleccionar bultos</button>
            </mat-card-footer>
        </mat-card>
    </div>
    <div class="exit">
        <button mat-raised-button color="primary" class="button btnlarge" (click)="salir()" >Volver al Pedido</button>
    </div>
</section>
