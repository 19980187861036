import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-nombrerunner',
  templateUrl: './nombrerunner.component.html',
  styleUrls: ['./nombrerunner.component.scss']
})
export class NombrerunnerComponent  implements OnInit {

  nombreRunner:string='';

  constructor( public pickupService : PickupService, public http: HttpClient, private router: Router) { }

  ngOnInit() {}


  guardar(){

    let url = environment.api_host+"/api/dispositivos/actualizar-runner";

    this.http.post(url,{rut:this.pickupService.runner.rut,nombre:this.nombreRunner}).subscribe((data:any)=>{

          this.pickupService.usuario.nombre_usuario=this.nombreRunner;
          this.router.navigate(['/']);

      });

  }

  gologin(){
    this.router.navigate(['/login']);
  }


}
