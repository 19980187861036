import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, ViewChild,ViewChildren, QueryList } from '@angular/core';
import { CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import { Router } from '@angular/router';
import { fromEvent, map } from 'rxjs';
import { PickupService } from 'src/app/services/pickup.service';
import { SocketService } from 'src/app/services/socket.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { UpdateService } from 'src/app/services/update.service';
import { EncuestaComponent } from 'src/app/modal/encuesta/encuesta.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  items = Array.from({length: 200000}).map((_, i) => `Item #${i}`);

  public folder: string = '';
  public currentUser:string = '';
  public mensaje:string = '';
  public panelOpenState = true;
  public grupo=0;

  public search:string='';

  @ViewChildren(CdkVirtualScrollViewport) viewPort: QueryList<CdkVirtualScrollViewport> | undefined;

  constructor(private chdtRef: ChangeDetectorRef, private router: Router,
    public dialog: MatDialog,
    public pickupService : PickupService, private viewportScroller: ViewportScroller,
    private socketService : SocketService, public utilityService: UtilitiesService,private updateService: UpdateService,
    ) {
  }

  async forceSync(){
    this.pickupService.programados_page = 1;
    await this.pickupService.syncronizarEnEspera();
    await this.pickupService.syncronizarBD();
    this.chdtRef.detectChanges();
  }

  ngOnInit(){

    this.updateService.checkForUpdates();

    if(this.pickupService.usuario.id === -1){
      this.router.navigate(['/login']);
      return;
    }

   
    console.log(this.pickupService.usuario);

  }

  async ngAfterViewInit(){

    await this.forceSync();
    this.pickupService.pedidosProgramadosFiltrados = this.pickupService.pedidosProgramados;
  }

  async goOpciones(item:any){
    this.pickupService.pedidoActivo = item;
    sessionStorage.setItem('pedidoActivo', JSON.stringify(item));
    this.router.navigate(['/opciones']).then(() => {
    });
  }

  goOpcionesLlegada(item:any){
    this.pickupService.pedidoActivo = item;
    this.router.navigate(['/opcionesnollegada']);
  }


  play(){
    this.socketService.playAudio('ring1.mp3');
  }

  async recargar(){
    await this.forceSync();
    this.pickupService.pedidosProgramadosFiltrados = this.pickupService.pedidosProgramados;
  }


  ir_inicio(){
    this.viewPort?.toArray().forEach(el => el.scrollToIndex(2, "smooth"));
  }


  test(){
    this.pickupService.syncronizarBD();
    this.pickupService.syncronizarEnEspera();
  }


  buscarProgramados(event: Event){


    this.pickupService.filtrarPedidosrogramados();

    if(!this.pickupService.pedidosProgramadosFiltrados.length) this.gotoBuscar();
  }

  borrarBusqueda(event: Event){

    if(!(this.pickupService.inputBuscar.length>0)){
      this.pickupService.pedidosProgramadosFiltrados = this.pickupService.pedidosProgramados;
    }

  }

  limpiarBusqueda(){
    this.pickupService.inputBuscar='';
    this.pickupService.pedidosProgramadosFiltrados = this.pickupService.pedidosProgramados;
  }

  gotoBuscar(){

    this.utilityService.changeDrawerState(false);
    this.router.navigate(['/buscar'],{ queryParams: { paramBuscar: 1 }});

  }
}

