import { Component, Inject, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';

import { ScannerQRCodeConfig} from 'ngx-scanner-qrcode';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent {

  @ViewChild('action') action: any;

  qr_data:string = '';

  error:boolean = false;
  context_message = '';

  number_of_devices:number = 0;

  public config: ScannerQRCodeConfig = {
    fps: 300,
    isBeep: false,
    deviceActive: 0,
    constraints: {
      audio: false,
      video: {
        width: window.innerWidth,
        // height: window.innerHeight,
        facingMode: 'environment'
      }
    },
  };

  constructor(private router: Router, public dialog: MatDialog, public dialogRefQR: MatDialogRef<ScannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public loginService : LoginService) {

  }

  ngAfterViewInit(){
    navigator.mediaDevices.enumerateDevices().then((devices) => {

      const video_devices = devices.filter(device => device.kind == 'videoinput');

      let device_id = video_devices.findIndex((device)  => device.label.includes('back'));

      console.log(device_id);

      if(device_id !== -1){
        this.action.deviceActive = device_id;
      }else{
        this.number_of_devices = video_devices.length;
      }

    }).catch(function(error) {
      console.error(error);
    });
    if(!this.action.isStart) setTimeout(() => {
      console.log(this.action);
      this.action.start();
    }, 500);
  }

  changeDevice(){
    this.action.deviceActive = (this.action.deviceActive + 1) % this.number_of_devices;
    this.action.stop();
    this.action.start();
    console.log(this.action.deviceActive);
  }

  ngOnDestroy(){
    this.action.stop();
  }

  volverAlLogin(){
    this.dialogRefQR.close();
  }

  async qrScan(data:any){

    if(!data[0]?.value.includes(this.qr_data)){
      this.qr_data = '';
    }

    if(data[0]?.value.includes('https://runner.pickupwm.ubq.cl/')){

      if(this.qr_data === ''){
        let qr_data_raw = data[0].value.split('/');
        this.qr_data = qr_data_raw[qr_data_raw.length - 1];
        this.action.pause();
        this.error = false;
        this.context_message = `Comprobando autenticidad...\nPor favor espere...`;

        const res:any = await this.loginService.validarQR(this.qr_data);

        if(res){
          this.context_message = `✅¡Escaneo exitoso!✅\n   Redireccionando... `;

          console.log(res);

          setTimeout(() => {
            this.dialogRefQR.close();
            this.loginService.valid_token_qr = this.qr_data;
            this.router.navigate(['/qr-credential', res.local_codigo]);
          }, 1500);

        }else{
          this.error = true;
          this.context_message = `Error de autenticidad de codigo.\nFavor contactar a soporte UBIQUITY`;
          this.action.play();
        }
      }
    }else{
      this.error = true;
      this.context_message = `¡Error! El código QR no es válido.`;
      this.qr_data =  data[0]?.value ?? '';
    }
  }
}