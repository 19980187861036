import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-bodega',
  templateUrl: './bodega.component.html',
  styleUrls: ['./bodega.component.scss']
})
export class BodegaComponent {

  constructor(private router: Router,public pickupService : PickupService, private utitlityService : UtilitiesService) {}

  ngOnInit() {
    this.pickupService.syncronizarBD();
  }

  goOpcionesBodega(){}

}
