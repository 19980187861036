<mat-card>
    <mat-card-header>
        <mat-card-title>Cliente en zona de espera</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <h4>Atencion!!</h4>
        <h5> Cliente en zona de entrega</h5>
    </mat-card-content>
</mat-card>



<mat-card (click)="goOpciones(item)" *ngFor="let item of pickupService.pedidosEsperando">
    <mat-card-header>
        <mat-card-title>{{ item.nombrecliente }}</mat-card-title>
        <mat-card-subtitle>{{ item.sg123 }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <table>
            <tr>
                <td>Orden</td>
                <td>{{ item.orden }}</td>
            </tr>
            <tr>
                <td>Email</td>
                <td>{{ item.email }}</td>
            </tr>
            <tr>
                <td>DP</td>
                <td>{{ item.dp }}</td>
            </tr>
            <tr>
                <td>Fecha retiro</td>
                <td>{{ item.fecharetiro }}</td>
            </tr>
            <tr>
                <td>Ventana</td>
                <td>{{ item.ventana }}</td>
            </tr>
            <tr>
                <td>Tiempo en espera</td>
                <td>{{ item.tiempo }}</td>
            </tr>
        </table>
    </mat-card-content>
    <mat-card-footer>
        <button mat-raised-button color="primary" class="button"    (click)="goCliente()" > Volver al listado</button>
    </mat-card-footer>
</mat-card>
