import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { StatusLogService } from 'src/app/services/status-log.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-picker-manual',
  templateUrl: './picker-manual.component.html',
  styleUrls: ['./picker-manual.component.scss']
})
export class PickerManualComponent {
  c1_pos:string[] = [];
  c2_pos:string[] = [];
  c3_pos:string[] = [];
  c4_pos:string[] = [];
  c1:string = 'A';
  c2:string = 'A';
  c3:string = '0';
  c4:string = '0';

  is_bt: boolean = false;

  constructor( private router: Router , public pickupService : PickupService, public utilityService: UtilitiesService, private statusService: StatusLogService) { }

  ngOnInit() {

    if(this.pickupService.pedidoActivo.tipo == 'BT') this.is_bt = true;

    this.c1_pos = this.is_bt ? ['BT'] : this.getLetters();
    this.c2_pos = this.is_bt ? ['88'] : this.getLetters();
    this.c3_pos = this.getNumbers();
    this.c4_pos = this.getNumbers();

    this.c1 = this.c1_pos[0];
    this.c2 = this.c2_pos[0];
    this.c3 = this.c3_pos[0];
    this.c4 = this.c4_pos[0];
  }


  getLetters() : string[]{
    let letters:string[] = [];

    for(let i = 65; i <= 90; i++){
      letters.push(String.fromCharCode(i));
    }

    return letters;
  }
  getNumbers() : string[]{
    let numbers:string[] = [];

    for(let i = 0; i <= 9; i++){
      numbers.push(i.toString());
    }

    return numbers;
  }

  seleccionar(){
    this.pickupService.codigoPosicion = `${this.c1}${this.is_bt ? '-' : ''}${this.c2}-${this.c3}-${this.c4}`;

    this.router.navigate(['/picker-manual-bultos']);
  }

  salir(){
    this.router.navigate(['/opciones']);
  }
}
