<div class="container">
    <header class="header">
        <img src="assets/images/logoacuenta.png" height='50%'/>
    </header>
    <main class="not-valid" *ngIf="not_valid">
        <h3>El codigo QR no es valido</h3>
        <p>Por favor, contactar con soporte</p>
        <p class="icon">👷‍♂️</p>
        <p class="counter" *ngIf="counter <= 3">Redirigiendo al login en {{counter}}</p>
    </main>
    <main class="main-login" *ngIf="!not_valid">
        <!-- INGRESO POR QR -->
        <form #form="ngForm" (ngSubmit)="login(form)" >
            <div class="title">
                <h3>Bienvenido</h3>
            </div>
            <div class="fields">
                <mat-form-field appearance="fill">
                    <mat-label>RUT</mat-label>
                    <input
                        matInput title="RUT"
                        name="rut"
                        type="rut"
                        id="rut"
                        minlength="8"
                        maxlength="12"
                        placeholder="11111111-1"
                        (keyup)="verificaRut($event)"
                        required
                    >
                </mat-form-field>
                <mat-form-field appearance="fill" floatLabel="always">
                    <mat-label>Teléfono</mat-label>
                    <input matInput
                        id="qr_number"
                        name="celular"
                        type="text"
                        (keyup)="numberOnlyValidation()"
                        minlength="9"
                        maxlength="9"
                        placeholder="963378877"
                        required
                    >
                    <span matTextPrefix>+56&nbsp;</span>
                    <mat-icon matSuffix>phone_iphone</mat-icon>
                </mat-form-field>
                <button mat-raised-button color="primary" class="btn-login" (click)="loginQR()" [disabled]="form.invalid || !rut_correcto || this.pickupService.runner.celular.length < 9"
                >
                    Ingresar
                </button>
                <div *ngIf="helper" class="error">
                    <p>Uno o ambos campos no cumplen con la condición minima</p>
                    <ul>
                        <li>El rut debe ser valido</li>
                        <li>Numero de teléfono debe tener al menos 9 dígitos</li>
                        <li>Debe acceder escaneando el código QR</li>
                    </ul>
                </div>
            </div>
        </form>
    </main>
    <footer class="footer">
        <img id="logo" src="../../assets/images/ubq.png">
    </footer>
</div>