<h2 mat-dialog-title>
    <mat-icon>notification_important</mat-icon>
    Atención
</h2>
<div>
    <div mat-dialog-content>
        <p class="disclaimer">{{data.disclaimer}}</p>
    </div>

    <div *ngIf="data.botones_opciones" mat-dialog-actions>
        <button mat-raised-button color="primary" class="btnlarge" (click)="exit(true)">Si, estoy seguro/a</button>
        <button mat-raised-button color="danger" class="btnlarge" (click)="exit(false)"> <mat-icon>arrow_back</mat-icon>No, volver al pedido</button>
    </div>
  <div *ngIf="!data.botones_opciones" mat-dialog-actions>

    <button mat-raised-button color="danger" class="btnlarge" (click)="exit(false)"> <mat-icon>arrow_back</mat-icon>volver al pedido</button>
  </div>
</div>
