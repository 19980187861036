import { Component, ViewChild } from '@angular/core';
import { LoginService } from './services/login.service';
import { PickupService } from './services/pickup.service';
import { Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { UtilitiesService } from './services/utilities.service';
import { QueueService } from './services/queue.service';
import packagejson from '../../package.json';
import { SocketService } from './services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'runnerApp';
  showFiller = false;
  public appVersion: string = packagejson.version;

  @ViewChild('drawer') drawer !: MatDrawer;

  constructor(public pickupService : PickupService, private utilitiesService: UtilitiesService, private socketService : SocketService,
    public loginService : LoginService, private queueService : QueueService, private router : Router, private provService : PickupService) {
      this.utilitiesService.drawerControllerEmitter$.subscribe((event:boolean) => {
        this.changeDrawerState(event);
      });
      this.queueService.queueHandler();
    }
    
    ngAfterViewInit(){
      this.utilitiesService.getObject('usuario').then((usuario:any) => {
        
        let usr = JSON.parse(usuario);
        if(usr.id){
          this.pickupService.usuario = usr;
          this.pickupService.local = usr.id_tienda;
          this.pickupService.usuario.nombre_usuario = usr.nombre_usuario;
          this.loginService.logeado = true;
          if(this.provService.usuario.codigo_local !== 0){
            this.socketService.conectarSocket();
          }
        console.log(window.location.href);
        if(window.location.href.includes('recepcion-bt')){
          this.router.navigate(['/recepcion-bt']);
        }else{
          this.router.navigate(['/inicio']);
        }
      }
    });
  }

  changeDrawerState(event:boolean){
    if(event) this.drawer.open();
    else this.drawer.close();
  }

}
