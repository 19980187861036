import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.scss']
})
export class BuscarComponent{

  public search_string:string="";
  public pedidos_encontrados:any;
  public pedido_local:boolean=false;
  public buscando:boolean=false;

  constructor(public http: HttpClient,private route: ActivatedRoute, private router: Router,public pickupService : PickupService, public utilityService : UtilitiesService) {}


  buscar(){
    let token = localStorage.getItem('token');

    this.pedido_local=false;
    this.pedidos_encontrados=false;
    let url = environment.api_host+"/acuenta/api-app/ordenes/buscar";

    this.buscando=true;
    this.http.post(url,{param:this.search_string,local_codigo:this.pickupService.local,token:token,username:this.pickupService.usuario.username}).subscribe((data:any)=>{
      this.buscando=false;
      if(data){

        let respuesta = JSON.parse(JSON.stringify(data));
        if(respuesta){
           this.pedidos_encontrados=respuesta;
        }else{
          this.pedidos_encontrados=[];
        } 

      }else{
        console.log('sin resultados');
        this.pedidos_encontrados=[];
      }
  },error =>{
    this.buscando=false;
  });

  }



  goOpcionesLlegada(item:any){
    this.pickupService.pedidoActivo = item;
    this.router.navigate(['/opciones']);
  }

  ngOnInit() {

    this.route.queryParams.subscribe(param=>{
      console.log(param['paramBuscar']);
      if(param['paramBuscar']==1){
          this.search_string = this.pickupService.inputBuscar;
          this.buscar();

      }
    })
    
  }
}
