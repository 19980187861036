<div class="product-item" [ngClass]="{'border-show': showBorder}">
  <div class="item-image-container">
    <img [appImageLoader]="product.photo_url" [alt]="product.name" class="item-image" [defaultImage]="defaultImage">
    <div class="quantity-badge">{{product.cantidad}}</div>
  </div>
  <div class="item-details">
    <h3 class="item-name">{{product.name ?? getCompostName(product.descripcion)}}</h3>
    <p class="item-description">{{product.descripcion}}</p>
  </div>
</div>
