<section>
    <div>
        <mat-card *ngFor="let b of barreras.barreras">
            <mat-card-header>
                <mat-card-title>
                    {{ b.nombre_barrera }}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <mat-icon [color]="(b.status==1)?'cancel':'check_circle_outline'">check_circle_outline</mat-icon>
            </mat-card-content>
            <mat-card-footer class="actionButtons">
                <button mat-raised-button color="primary" class="button"  (click)="ejecutarBarrera(b.id)"  >
                    Abrir/Cerrar
                </button>
            </mat-card-footer>
        </mat-card>
    </div>
</section>