<section>
    <div>
        <mat-card  *ngFor="let item of pickupService.pedidosProgramados"> 
            <mat-card-header> 
                <mat-card-title>{{ item.sg123 }}</mat-card-title> 
                <mat-card-subtitle>{{ item.nombrecliente }}</mat-card-subtitle> 
            </mat-card-header>
            <mat-card-content>
                <table>
                    <tr> 
                        <td><mat-icon>directions_run</mat-icon></td>
                        <td>Orden</td>
                        <td>{{ item.orden }}</td>
                    </tr>
                    <tr> 
                        <td><mat-icon>person</mat-icon></td>
                        <td>Email</td>
                        <td>{{ item.email }}</td>
                    </tr>
                    <tr> 
                        <td><mat-icon>person</mat-icon></td>
                        <td>Patente</td>
                        <td>{{ item.patente }}</td>
                    </tr>
                    <tr> 
                        <td><mat-icon>person</mat-icon></td>
                        <td>Estado Pedido</td>
                        <td>{{ item.status }}</td>
                    </tr>
                    <tr> 
                        <td><mat-icon>person</mat-icon></td>
                        <td>Localización</td>
                        <td>{{ item.status }}</td>
                    </tr>
                    <tr> 
                        <td><mat-icon>person</mat-icon></td>
                        <td>Runner asignado </td>
                        <td>{{ item.runner }}</td>
                    </tr>
                    <tr> 
                        <td><mat-icon>person</mat-icon></td>
                        <td>Cliente en camino</td>
                        <td>{{ item.eta_slug }}</td>
                    </tr>
                </table>
            </mat-card-content> 
        </mat-card>
    </div>
     
</section>
