import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
import { QrCredentialComponent } from './authentication/qr-credential/qr-credential.component';
import { BuscarComponent } from './pages/buscar/buscar.component';
import { ColaenvioComponent } from './pages/colaenvio/colaenvio.component';
import { ComprasPreparacionComponent } from './pages/compras-preparacion/compras-preparacion.component';
import { InfoRetiraComponent } from './pages/info-retira/info-retira.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { InstruccionesfotoComponent } from './modal/instruccionesfoto/instruccionesfoto.component';
import { OpcionesComponent } from './pages/opciones/opciones.component';
import { PickerManualBultosPrepComponent } from './pages/picker-manual-bultos-prep/picker-manual-bultos-prep.component';
import { PickerManualBultosComponent } from './pages/opciones/picker-manual/picker-manual-bultos/picker-manual-bultos.component';
import { PickerManualPrepComponent } from './pages/picker-manual-prep/picker-manual-prep.component';
import { PickerManualComponent } from './pages/opciones/picker-manual/picker-manual.component';
import { PickerComponent } from './pages/picker/picker.component';
import { PuertasComponent } from './pages/puertas/puertas.component';
import { ReagendarComponent } from './pages/opciones/reagendar/reagendar.component';
import { RechazobultoComponent } from './pages/rechazobulto/rechazobulto.component';
import { ResultadoComponent } from './pages/resultado/resultado.component';
import { RunnersComponent } from './pages/runners/runners.component';
import { RutRunnerComponent } from './pages/rut-runner/rut-runner.component';
import { ScannerComponent } from './template/scanner/scanner.component';
import { OpcionesEntregaComponent } from './pages/opciones/opciones-entrega/opciones-entrega.component';
import { RecepcionBtComponent } from './pages/recepcion-bt/recepcion-bt.component';
import { ComentarioComponent } from './pages/opciones/comentario/comentario.component';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'recepcion-bt',
    component: RecepcionBtComponent,
    pathMatch: 'full'

  },
  {
    path: 'opciones',
    component: OpcionesComponent,
    pathMatch: 'full',
  },
  {
    path: 'opciones-entrega',
    component: OpcionesEntregaComponent,
    pathMatch: 'full',
  },
  {
    path: 'comentarios',
    component: ComentarioComponent,
    pathMatch: 'full',
  },
  {
    path: 'inicio',
    component: InicioComponent,
    pathMatch: 'full',
  },
  {
    path: 'buscar',
    component: BuscarComponent,
    pathMatch: 'full',
  },
  {
    path: 'colaenvio',
    component: ColaenvioComponent,
    pathMatch: 'full',
  },
  {
    path: 'info-retira',
    component: InfoRetiraComponent,
    pathMatch: 'full',
  },
  {
    path: 'instrucciones-foto',
    component: InstruccionesfotoComponent,
    pathMatch: 'full',
  },
  {
    path: 'picker-manual',
    component: PickerManualComponent,
    pathMatch: 'full',
  },
  {
    path: 'picker-manual-bultos',
    component: PickerManualBultosComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'compras-preparacion',
    component: ComprasPreparacionComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'picker',
    component: PickerComponent,
    pathMatch: 'full',
  },
  {
    path: 'picker-manual-prep',
    component: PickerManualPrepComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'picker-manual-bultos-prep',
    component: PickerManualBultosPrepComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'rechazobulto',
    component: RechazobultoComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'puertas',
    component: PuertasComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'reagendar',
    component: ReagendarComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'resultado',
    component: ResultadoComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'runners',
    component: RunnersComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'rut-runner',
    component: RutRunnerComponent,
    pathMatch: 'full',
  }, 
  {
    path: 'scanqr',
    component: ScannerComponent,
    pathMatch: 'full',
  },
  {
    path: 'qr-credential/:codigo_local',
    component: QrCredentialComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
