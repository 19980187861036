import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { SocketService } from 'src/app/services/socket.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-runners',
  templateUrl: './runners.component.html',
  styleUrls: ['./runners.component.scss']
})
export class RunnersComponent {

  listaRunners:any = [];

  constructor(private router: Router, public pickupService : PickupService, public http: HttpClient, private socketService : SocketService) {
  }

  seleccionarRunner(runner:any){
    this.pickupService.runner=runner;
    this.router.navigate(['/']);
  }

  ionViewDidEnter(){

    // this.socketService.conectarSocket();

    this.pickupService.syncronizarBD(1);
    this.pickupService.syncronizarEnEspera();


    const url = environment.api_host+'/api/dispositivos/runners';

    this.http.post(url,{local_codigo:this.pickupService.local}).subscribe((data:any)=>{
        this.listaRunners = JSON.parse(JSON.stringify(data));
        console.log(this.listaRunners);
    });
  }

}