import { Component } from '@angular/core';

import { Router } from '@angular/router';
@Component({
  selector: 'app-resultado',
  templateUrl: './resultado.component.html',
  styleUrls: ['./resultado.component.scss']
})
export class ResultadoComponent {

  constructor(private router: Router ) { }
  volverListado(){    
    this.router.navigate(['/inicio']);
  }
}
