import {Component, HostListener, Input} from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { PickupService } from 'src/app/services/pickup.service';
import { SocketService } from 'src/app/services/socket.service';


@Component({
  selector: 'app-header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent   {
  @Input() online:boolean = false;
  @Input() usuario: string = '';
  @Input() local: string = '';

  @HostListener('window:online', ['$event']) onOnline(event:any) {
    this.socketService.checkCurrentConnectionStatus();
  }

  @HostListener('window:offline', ['$event']) onOffline(event:any) {
    this.socketService.checkCurrentConnectionStatus();
  }

  constructor(private utilitiesService : UtilitiesService,public provService : PickupService, public socketService : SocketService) {
    setInterval(() => {
      this.socketService.checkCurrentConnectionStatus();
    }, 30 * 1000);
  }

  switchDrawer(){
    this.utilitiesService.changeDrawerState(true);
  }



}
