import {Component, Input} from '@angular/core';
import {Product} from "../../../../interfaces/product";

@Component({
  selector: 'app-product-showcase',
  templateUrl: './product-showcase.component.html',
  styleUrls: ['./product-showcase.component.scss']
})
export class ProductShowcaseComponent {

  @Input() products: Product[] = [
    // {
    //   id: 'AA21',
    //   name: 'Producto AA21',
    //   descripcion: 'Descripción del producto AA21',
    //   photo_url: 'https://images.lider.cl/wmtcl?source=url[file:/productos/2413a.jpg]&sink',
    //   cantidad: '1'
    // },
  ];

}
