<section>
    <div>
        <mat-card >
            <mat-card-header>
                <mat-card-title>Registro Runner</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <h1>{{ pickupService.usuario.nombre_local }}</h1>
            </mat-card-content>
        </mat-card>
    </div>
</section>