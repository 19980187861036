<section>
    <div style="text-align:center; background-color: #f9f8f4;">
        <img src="assets/images/baby.jpeg" height="60"/>
</div>
    <div>
        <mat-card >
            <mat-card-header> 
                <mat-card-title>{{ pickupService.pedidoActivo.nombrecliente }}</mat-card-title> 
                <mat-card-subtitle>{{ pickupService.pedidoActivo.sg123 }}</mat-card-subtitle> 
            </mat-card-header> 
            <mat-card-content> 
                <table>
                    <tr>
                        <td><mat-icon>local_play</mat-icon></td>
                        <td>Orden</td> 
                        <td>{{ pickupService.pedidoActivo.orden }}</td> 
                    </tr>
                    <tr>
                        <td><mat-icon>person</mat-icon></td>
                        <td>Rut</td> 
                        <td>{{ pickupService.pedidoActivo.rut }}</td> 
                    </tr>
                    <tr>
                        <td><mat-icon>event_available</mat-icon></td>
                        <td>Fecha retiro</td> 
                        <td>{{ pickupService.pedidoActivo.fecharetiro }}</td> 
                    </tr>
                    <tr>
                        <td><mat-icon>access_time</mat-icon></td>
                        <td>Ventana</td> 
                        <td>{{ pickupService.pedidoActivo.ventana }}</td> 
                    </tr>
                </table>
            </mat-card-content> 
        </mat-card>
    </div> 
    <div>
        <mat-card >
            <mat-card-header> 
                <mat-card-title>Seleccione ubicación</mat-card-title>  
            </mat-card-header> 
            <mat-card-content class="row">  
                <mat-form-field class="example-full-width">
                <mat-select  disabled value="BT">
                    <mat-option value="BT">BT</mat-option> 
                </mat-select>
                </mat-form-field>
            
                <mat-form-field class="example-full-width">
                    <mat-select  disabled  value="88">
                    <mat-option value="88">88</mat-option>
                    
                    </mat-select>
                </mat-form-field>
            
                <mat-form-field class="example-full-width">
                    <mat-select [(ngModel)]="c3">
                    
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    
                    </mat-select>
                </mat-form-field>
            
                <mat-form-field class="example-full-width">
                    <mat-select [(ngModel)]="c4">
                    
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    <mat-option value="6">6</mat-option>
                    
                    </mat-select>
                </mat-form-field>
            
            </mat-card-content> 
            <mat-card-footer class="actionButtons">                
                <button mat-raised-button style="width: 100%;" color="primary" class="button btnlarge" (click)="seleccionar()"  > 
                    Seleccionar bultos >
                </button> 
               
            </mat-card-footer>
        </mat-card>
    </div> 
</section>