import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment.production';
import { PickupService } from './pickup.service';
import { SocketService } from './socket.service';
import { UtilitiesService } from './utilities.service';
import { MatDialog } from '@angular/material/dialog';
import { DisclaimerComponent } from '../modal/disclaimer/disclaimer.component';
import packagejson from '../../../package.json';
import { CambiopasswordComponent } from '../modal/cambiopassword/cambiopassword.component';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public appVersion: string = packagejson.version;
  logeado: boolean = false;
  badlogin = false;
  login_loader:boolean = false;

  valid_token_qr:string = '';

  constructor(
    readonly swUpdate: SwUpdate,
    public http: HttpClient,
    private router: Router,
    public dialog: MatDialog,
    private provService : PickupService,
    private socketService: SocketService,
    private utilityService: UtilitiesService
  ) {
    this.valid_token_qr = '';
    this.provService.updateEmitter$.subscribe((event:any) => {
      switch(event){
        case 'LogOut':
          this.logout();
          break;
        default: break;
      }
    })
  }

  async loginQr(login_info:any) {
    // this.provService.loading=true;
    let url = environment.api_host+"/api/dispositivos/login-codigo";

    console.table(login_info);

    this.http.post(url, { rut: login_info.rut, celular: login_info.celular, local_codigo: login_info.codigo_local, qr_data: login_info.qr_data}).subscribe( async (data) => {
      console.log(data);
      if (data) {
        let respuesta = JSON.parse(JSON.stringify(data));
        console.log(respuesta);

        this.provService.local = respuesta.local_codigo;
        this.provService.usuario.nombre_usuario = login_info.rut;
        this.logeado = true;

        this.provService.usuario = {
          id: respuesta.runner.id,
          codigo_local: respuesta.local_codigo,
          id_tienda: respuesta.local_codigo,
          nombre_local: respuesta.nombre_local,
          rut: login_info.rut,
          nombre_usuario: login_info.rut,
          celular: login_info.celular,
          logeado: 1,
          username: login_info.rut,
          silenciado: login_info.silenciado
        };

        localStorage.setItem('token',respuesta.runner.token);

        console.log(this.provService.usuario);

        localStorage.setItem("usuario", JSON.stringify(this.provService.usuario));
        this.utilityService.setObject("logeado", true);
        this.utilityService.setObject("usuario", this.provService.usuario);
        this.utilityService.setObject("celular", { celular: this.provService.runner.celular });
        this.provService.programados_next = true;
        this.provService.programados_page = 1;
        this.socketService.conectarSocket();
        await this.provService.syncronizarBD();

        this.router.navigate(["/inicio"]);

      } else {
        console.log("qr invalido");
      }
    },error => {
      console.error(error);
    });
  }

  async validarQR(qr_data:string){
    try {
      let data = await lastValueFrom(this.http.post(`${environment.api_host}/api/dispositivos/check-qr`, {qr: qr_data}),{defaultValue: false}) ?? false;
      return data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async login(form:any) {
    this.provService.loading = true;
    const headers = { 'Authorization': 'e8a7154cd311dd2fd4bfebe8506a0a805178c576','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'*' };
    let url = environment.api_host+"/api-app/login-pwa-runner";
    let email = form.value.email;
    let password = form.value.password;
    this.provService.runner.celular = form.value.celular;

    this.http
      .post(url, {
        username: email,
        password: password,
        version: this.appVersion,
        celular: "56" + this.provService.runner.celular,
      },{headers})
      .subscribe( async (data) => {
        this.provService.loading = false;

        let respuesta = JSON.parse(JSON.stringify(data));

        console.log(respuesta);

        if (respuesta.status == true) {

          if(respuesta.usuario.cambio_password == 1){
            return this.cambiarPassword(respuesta.usuario, form)
          }

          if(!this.compareVesion(respuesta.usuario.min_version, this.appVersion)){
            return this.updateApp(respuesta.usuario.min_version);
          }

          this.provService.local = respuesta.usuario.id_tienda;
          this.provService.usuario.nombre_usuario = respuesta.usuario.name;
          this.logeado = true;

          this.provService.usuario = {
            id: respuesta.usuario.id,
            codigo_local: respuesta.usuario.id_tienda,
            id_tienda: respuesta.usuario.id_tienda,
            nombre_local: respuesta.usuario.nombre_local,
            rut: "",
            nombre_usuario: respuesta.usuario.name,
            celular: this.provService.runner.celular,
            logeado: 1,
            username:respuesta.usuario.username,
            silenciado: respuesta.usuario.silenciado
          };

          localStorage.setItem('token',respuesta.usuario.token);

          console.log(this.provService.usuario);

          this.provService.llamado_activado=1;
          let url = environment.api_host+"/api/dispositivo/silenciartelefono";
          this.http.post(url,{user_id:respuesta.usuario.id, silenciado:0}).subscribe(data=>{
              console.log(data);
          });

          localStorage.setItem("usuario", JSON.stringify(this.provService.usuario));
          this.utilityService.setObject("logeado", true);
          this.utilityService.setObject("usuario", this.provService.usuario);
          this.utilityService.setObject("celular", { celular: this.provService.runner.celular });
          this.provService.programados_next = true;
          this.provService.programados_page = 1;
          this.socketService.conectarSocket();
          await this.provService.syncronizarBD();
          this.login_loader = false;
          this.router.navigate(["/inicio"]);
        } else {
          this.login_loader = false;
          this.badlogin = true;
        }
      });
  }

  async logout(){
    var usr =  this.provService.usuario;
    this.provService.usuario={'id':-1,'logeado':0,'rut':'','nombre_usuario':'','celular':'','codigo_local':0,'id_tienda':0,'nombre_local':'','username':'','silenciado':0};
    this.provService.runner = {id: -1,nombre:'', rut:'', token: '', celular: ''};
    this.socketService.stopAudio();
    this.socketService.socket.emit('leave', this.provService.local);
    this.socketService.socket.disconnect();
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('_cap_usuario');
    localStorage.removeItem('_cap_logeado');
    this.logeado = false;
    this.utilityService.changeDrawerState(false);

    let url = environment.api_host+"/api/dispositivo/eliminartelefono";

    await this.http.post(url,{local:usr.codigo_local, celular:usr.celular}).toPromise();

    this.router.navigate(['/login']);
  }

  async changePassword(new_password:string, user_id:string, current_password:string) : Promise<{status:boolean, data:any}>{
    try {
      const response = await lastValueFrom(this.http.post(`${environment.api_host}/api-app/cambiar-password`, {new_password, user_id, password: current_password}),{defaultValue: false}) ?? false;

      return {
        status: true,
        data: response
      };
    } catch (error) {
      console.error(error);
      return {status: false, data: error};
    }
  }


  compareVesion(server_version:string, current_version:string) : Boolean{
    let current_version_array = current_version.split('.');
    let server_version_array = server_version.split('.');

    for(let i = 0; i < current_version_array.length; i++){
      if(parseInt(current_version_array[i]) < parseInt(server_version_array[i])){
        return false;
      }
    }

    return true;
  }

  updateApp(version:string = '1.0.0'){
    const modal = this.dialog.open(DisclaimerComponent, {
      height: (this.utilityService.modal_height) + 'px',
      width: (this.utilityService.modal_width) + 'px',
      maxHeight : (window.screen.height) + 'px',
      maxWidth : (window.screen.width) + 'px',
      data: {
        version: version,
        entry: 'version'
      }
    });

    modal.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  cambiarPassword(usuario:{cambio_password:string, id:string, username:string}, form:any){
    const modal = this.dialog.open(CambiopasswordComponent, {
      height: (this.utilityService.modal_height) + 'px',
      width: (this.utilityService.modal_width) + 'px',
      maxHeight : (window.screen.height) + 'px',
      maxWidth : (window.screen.width) + 'px',
      data: {
        usuario,
        password: form.value.password,
      }
    });

    modal.afterClosed().subscribe(result => {
      if(result.status){
        this.login_loader = true;
        setTimeout(() => {
          this.login_loader = false;
        }, 20 * 1000);
        form.value.password = result.new_password;
        this.login(form);
      }
    });
  }

}
