import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Product} from "../../../../interfaces/product";

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ProductItemComponent {
  @Input() product!: Product;
  @Input() showBorder:boolean = false;
  defaultImage = 'assets/images/defaultProductImage.png';

  getCompostName(descripcion:string){
    const splittedWord = descripcion.split(' ');
    return `${splittedWord[0]} ${splittedWord[splittedWord.length - 1]}`;
  }

}
