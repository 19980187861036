import { Component } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-reagendar',
  templateUrl: './reagendar.component.html',
  styleUrls: ['./reagendar.component.scss']
})
export class ReagendarComponent {

  dias: string[] = [];
  num_fechas:number = 7;
  dia: string = "";

  hora: string = "";
  error: boolean = false;

  constructor(
    public pickupService: PickupService,
    public utilityService:UtilitiesService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.error = false;
    this.dia = '';
    this.loadDates();
    document.querySelectorAll('.button-dia').forEach((el) => {
      el.classList.remove('selected');
    });
  }

  loadDates(){
    this.dias = [];
    for(let i = 0; i <= this.num_fechas; i++){
      this.dias.push(moment(Date.now() + i * 24 * 60 * 60 * 1000).format("DD/MM/YYYY"));
    }
  }

  selectDay(event:any, fecha:any){
    event.stopPropagation();

    document.querySelectorAll('.button-dia').forEach((el) => {
      el.classList.remove('selected');
    });

    event.target.classList.add("selected");

    this.dia = fecha;
  }

  async reagendar() {
    this.error = false;

    let dia = this.dia.split("/").reverse().join("-");
    let hora = this.hora;

    this.dia = '';
    this.hora = '';

    document.querySelectorAll('.button-dia').forEach((el) => {
      el.classList.remove('selected');
    });

    console.table({dia: dia, hora: hora});

    if((await this.pickupService.reagendarPedido(dia, hora))){
      this.volver();
    }else{
      this.error = true;
    }

    console.log(this.hora + " " + this.dia);
  }

  volver(){
    this.router.navigate(['/opciones']);
  }
}
