import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { InstruccionescodigoComponent } from 'src/app/modal/instruccionescodigo/instruccionescodigo.component';
import { InstruccionesfotoComponent } from 'src/app/modal/instruccionesfoto/instruccionesfoto.component';
import { PickupService } from 'src/app/services/pickup.service';
import { QueueService } from 'src/app/services/queue.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-opciones-entrega',
  templateUrl: './opciones-entrega.component.html',
  styleUrls: ['./opciones-entrega.component.scss']
})
export class OpcionesEntregaComponent {

    constructor(
      public pickupService: PickupService,
      public queueService: QueueService,
      private router: Router,
      public dialog: MatDialog,
      public utilityService : UtilitiesService
    ) { }

    loader:boolean = true;

    comentario:string = '';

    screen_height:number = window.screen.height;
    screen_width:number = window.screen.width;

    ngOnInit(): void {}

    // showLoader(){
    //   if(this.pickupService.cola_firma.length){
    //     this.loader = true;
    //   }else{
    //     this.loader = false;
    //   }

    //   setTimeout(() => {
    //     if(this.loader) this.showLoader();
    //   }, 100);
    // }

    async resizeImage(image:any) {
      this.pickupService.pedidoActivo.loader_entrega = 'entregando';
      this.pickupService.pedidoActivo.tipo_entrega = 'foto';

      // this.pickupService.entregarPedidoFirma(this.pickupService.pedidoActivo);

      // this.showLoader();

      let img = new Image();
      img.src = image;

      img.onload = () => {

        let canvas = document.createElement('canvas');
        let canvasCopy = document.createElement("canvas");
        let copyContext = canvasCopy.getContext("2d");
        let canvasContext = canvas.getContext('2d');

        let MAX_WIDTH = 400;
        let MAX_HEIGHT = 400;

        let ratio = 1;
        if(img.width > MAX_WIDTH)
          ratio = MAX_WIDTH / img.width;
        else if(img.height > MAX_HEIGHT)
          ratio = MAX_HEIGHT / img.height;

        canvasCopy.width = img.width;
        canvasCopy.height = img.height;
        copyContext?.drawImage(img, 0, 0);

        this.pickupService.pedidoActivo.imagen_firma = canvasCopy.toDataURL();

        console.log(this.pickupService.pedidoActivo.imagen_firma);

        // this.pickupService.entregarPedidoFoto(this.pickupService.pedidoActivo);
        this.queueService.agregarColaLoop(this.pickupService.pedidoActivo);
      };

    }

    async presentModalInstruccionesCodigo(){
      const modal = this.dialog.open(InstruccionescodigoComponent, {
        height: (this.utilityService.modal_height) + 'px',
        width: (this.utilityService.modal_width) + 'px',
        maxHeight : (this.screen_height) + 'px',
        maxWidth : (this.screen_width) + 'px',
      });

      modal.afterClosed().subscribe((data:{success:boolean}) => {
        const {success} = data;

        if(success){
          this.pickupService.pedidoActivo.tipo_entrega = 'codigo';
          this.pickupService.pedidoActivo.loader_entrega = 'entregando';
          // this.pickupService.entregarPedidoFirma(this.pickupService.pedidoActivo);

          // let shallow_pedido = {...this.pickupService.pedidoActivo};

          // this.queueService.agregarColaLoop(shallow_pedido);

          this.queueService.agregarColaLoop(this.pickupService.pedidoActivo);

          // this.presentModalInfoRetira(this.pickupService.pedidoActivo.orden);
          this.router.navigate(['/comentarios'], { queryParams: { tipo_entrada: 'entrega' }});
        }
      });
    }

    async presentModalInstruccionesFoto() {
      const modal = this.dialog.open(InstruccionesfotoComponent, {
        height: (this.utilityService.modal_height) + 'px',
        width: (this.utilityService.modal_width) + 'px',
        maxHeight : (this.screen_height) + 'px',
        maxWidth : (this.screen_width) + 'px',
      });

      modal.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        if(result?.photo){
          // this.presentModalInfoRetira();
          this.resizeImage(result.photo);
          this.router.navigate(['/comentarios'], { queryParams: { tipo_entrada: 'entrega' }});
        }
      });
    }

    volver(){
      this.router.navigate(['/opciones']);
    }

}
