import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { PickupService } from 'src/app/services/pickup.service';
import { SocketService } from 'src/app/services/socket.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-qr-credential',
  templateUrl: './qr-credential.component.html',
  styleUrls: ['./qr-credential.component.scss']
})
export class QrCredentialComponent {

  helper:boolean = false;
  rut:string = '';
  rut_correcto:boolean = false;

  counter:number = 5;

  screen_height:number = window.screen.height;
  screen_width:number = window.screen.width;

  codigo_local:string = '';
  not_valid:boolean = false;

  constructor(public pickupService : PickupService, private router: Router, public loginService : LoginService, public dialog: MatDialog, private route : ActivatedRoute) {}

    ngOnInit(): void{

      this.route.paramMap.subscribe(async (params: ParamMap) =>{
        this.codigo_local = params.get('codigo_local') || '';

        if(this.codigo_local.length > 3 && this.loginService.valid_token_qr === ''){
          let res:any = await this.loginService.validarQR(this.codigo_local);

          if(res){
            this.not_valid = false;
            // this.loginService.valid_token_qr = this.codigo_local;
            this.codigo_local = res.local_codigo;
          }else{
            this.not_valid = true;
            this.redireccion();
          }
        }
      });
    }

  loginQR(){
      if(this.loginService.valid_token_qr === ''){
        this.not_valid = true;
        return this.redireccion();
      }

    if(this.rut_correcto && this.pickupService.runner.celular.length == 9){
      this.pickupService.runner.rut = this.rut;
      this.helper = false;
      this.loginService.loginQr({rut: this.rut, celular: `56${this.pickupService.runner.celular}`, codigo_local: this.codigo_local, qr_data: this.loginService.valid_token_qr});
    }else{
      this.helper = true;
    }
  }

  redireccion(){
    let timeout = setTimeout(() => {
      this.counter = 5;
      let interval = setInterval(() => {
        this.counter--;
        if(this.counter < 1){
          clearTimeout(timeout);
          clearInterval(interval);
          this.router.navigate(['/']);
        }
      }, 1000);
    }, 1000);
  }

  login(form:any){
    // form.prevent.default();
  }

  verificaRut($event:any) {
    //Retorna el input a traves de la busque de ejecución de eventos
    let input_value = document.getElementById('rut') as HTMLInputElement;
    if(input_value.value.length>10) return input_value.value = input_value.value.substring(0, 10);

    //Limpia el String y formatea el Input
    let cleaned_rut = ($event.key.toLowerCase() === 'k' && input_value.value.length < 8) ? input_value.value.replace(/[^0-9-]/g, "") : input_value.value.replace(/[^0-9kK-]/g, "");
    cleaned_rut = cleaned_rut.replaceAll('-','');
    cleaned_rut = cleaned_rut.slice(0, -1) + '-' + cleaned_rut.slice(-1);
    input_value.value = cleaned_rut;
    this.rut = input_value.value;
    const splitted_rut = cleaned_rut.split("-");
    if (splitted_rut.length !== 2) return this.rut_correcto = false;
    if (splitted_rut[0].length < 7) return this.rut_correcto = false;
    const rut_body = parseInt(splitted_rut[0], 10);
    const digito_verificador = splitted_rut[1] === 'K' ? 'k' : splitted_rut[1];
    if(splitted_rut[0].includes('k') || splitted_rut[0].includes('K') ) return this.rut_correcto = false;

    const dgv_calculado = this.calculateDV(rut_body);
    console.log(dgv_calculado, digito_verificador);
    if(dgv_calculado === digito_verificador) return this.rut_correcto = true;

    return this.rut_correcto = false;

  }

  calculateDV(rut:any) {
    const rut_body_string = rut.toString();

    let sum = 0;
    let multiple = 2;

    for (let i = 1; i <= rut_body_string.length; i++) {

      const index = multiple * rut_body_string.charAt(rut_body_string.length - i);
      sum += index;

      if (multiple < 7) multiple += 1;
      else multiple = 2;
    }
    const digito_verificador_esperado = 11 - (sum % 11);
    if (digito_verificador_esperado === 10) return "k";
    if (digito_verificador_esperado === 11) return "0";
    return digito_verificador_esperado.toString();
  }

  numberOnlyValidation(){
    let input_value = document.getElementById('qr_number') as HTMLInputElement;

    if(input_value.value.length > 10) return input_value.value = input_value.value.substring(0, 10);

    let cleaned_number = input_value.value.replace(/[^0-9]/g, "");

    input_value.value = cleaned_number;

    this.pickupService.runner.celular = cleaned_number;
  }

}
