import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from './pickup.service';
import { UtilitiesService } from './utilities.service';
import { Pedido } from '../interfaces/Pedido';
import { lastValueFrom, Subject } from "rxjs";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatusLogService {



  constructor(
    public http: HttpClient,
    private router: Router,
    private pickupService : PickupService,
    private utilityService : UtilitiesService) {}

  async changePedidoStatus(pedido:Pedido, status:number){

    // return false;

    // const current_status = await this.getPedidoStatus(pedido);

    if(pedido.status !== 'Cliente esperando') return;

    try {
      let data = await (lastValueFrom(this.http.post(`${environment.api_host}/api/cambiar-pedido-status-runner`, {
        status: status,
        sg123: pedido.sg123
      })));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getPedidoStatus(pedido:Pedido){
    try {

      let data = await (lastValueFrom(this.http.post<number>(`${environment.api_host}/api/get-pedido-status-runner`, {
        sg123: pedido.sg123
      })));

      return data;
    } catch (error) {
      console.error(error);
      return -1;
    }
  }


}
