import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Socket } from 'ngx-socket-io';
import { Howl } from "howler";
import { PickupService } from './pickup.service';
import { UtilitiesService } from './utilities.service';
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  socketStatus = false;
  player: any
  currentUser: string = '';


  keepConnectedTimeout:any;


  constructor(
    readonly swUpdate: SwUpdate,
    public http: HttpClient,
    public socket: Socket,
    private router: Router,
    private provService : PickupService,
    private utilityService : UtilitiesService) { }

    conectarSocket() {
      var status = this.socket.connect();
      this.keepConnected();

      if (status) {
        this.socketStatus = true;
      }

      this.socket.on("disconnect", () => {
        this.socketStatus = false;
        console.log("Se ha perdido la conexión con el socket server");
      });

      this.socket.on("cliente_en_camino", () => {



      });

      this.socket.on("connect", () => {
        this.socketStatus = true;

        this.socket.emit("join", this.provService.usuario.codigo_local);
        console.log("Se ha entablado satisfactoriamente un conexión con el socket server");
      });

      this.socket.on("error", (error:any) => {
        console.log(error);
      });

      this.socket.fromEvent("notificacion_llegada").subscribe(
        (message: any) => {
          let aux = JSON.stringify(message);
          let _aux = JSON.parse(aux);
          console.table(_aux);

          if (_aux.msg == "updateapp") {
            this.swUpdate.checkForUpdate();
          } else if (_aux.msg == "actualizar") {
            this.provService.syncronizarBD();
            this.provService.syncronizarEnEspera();
          } else if (_aux.msg == "cliente_en_camino") {
            this.playAudio("beep_cliente_en_camino.mp3");
            this.provService.syncronizarBD();
            this.provService.syncronizarEnEspera();
          } else if (_aux.msg == "llegada_cliente") {
            this.playAudio("ring1.mp3");
            this.provService.syncronizarBD();
            this.provService.syncronizarEnEspera();
            this.router.navigate(["/llegadacliente"]);
          }else if(_aux.msg == 'heartbeat'){
            this.keepConnected();
          }
        },
        (error) => {
          console.log(error);
        }
      );

      let name = `RunnerAPP-${this.provService.usuario.codigo_local}-${this.provService.usuario.celular}-${new Date().getTime()}`;
      this.currentUser = name;
      this.socket.emit("set-name", name);
    }

    playAudio(file: string) {
      this.player = new Howl({
        src: ["assets/sound/" + file],
      });

      this.player.play();
    }

    stopAudio() {
      // this.player.stop();
    }

    checkCurrentConnectionStatus(){
      this.provService.online = navigator.onLine;
    }

    keepConnected(){
      clearTimeout(this.keepConnectedTimeout);
      this.provService.online = true;
      this.keepConnectedTimeout = setTimeout(() => {
        this.provService.online = false;
        console.log(this.provService.online);
      }, 90 * 1000);
    }
}
