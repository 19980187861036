<mat-progress-bar mode="indeterminate" *ngIf="utilityService.loadingLabel"></mat-progress-bar>
<!-- <div style="width: 100%; height: 50px; position: fixed; background-color: red; top: 0; left: 0;" #overlap></div> -->
<section>
<!-- ACT-->

    <mat-accordion>
        <mat-expansion-panel [disabled]="!(pickupService.pedidosEsperando.length>0)"  [expanded]="pickupService.pedidosEsperando.length>0"   (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header style="flex-grow:1">
                <mat-panel-title>
                    <mat-icon>person_pin_circle</mat-icon>&nbsp;Clientes esperando
                </mat-panel-title>
                <mat-panel-description  style="flex-grow:0;height:30px;padding-right:10px;">
                    <div *ngIf="(pickupService.pedidosEsperando.length>0)" class="blink" matBadge="{{ pickupService.pedidosEsperando.length }}" matBadgeSize="large" matBadgeColor="warn"></div>
                    <div *ngIf="!(pickupService.pedidosEsperando.length>0)" matBadge="0" matBadgeSize="large"></div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="example-viewport" cdkVirtualScrollingElement  style="min-height: 450px;">
                <cdk-virtual-scroll-viewport #cmp itemSize="5">
                        <mat-card  *cdkVirtualFor="let item of pickupService.pedidosEsperando; let index = index;
                        let count = count;
                        let first = first;
                        let last = last;
                        let even = even;
                        let odd = odd;" (click)="goOpciones(item)">
                            <mat-card-header [ngClass]="{'success' : item.segundos <= 180, 'warning' : item.segundos > 180 && item.segundos <= 300 , 'danger' : item.segundos > 300}">
                                <img *ngIf="item.tipo=='food'" class="icontipo" src="assets/images/sod.png">
                                <img *ngIf="item.tipo=='BT'" class="icontipo" src="assets/images/babytuto.png">
                                <img *ngIf="item.tipo=='Catex'" class="icontipo" src="assets/images/catex.png">
                                
                                <mat-card-title>#{{ index+1 }}&nbsp;{{ item.nombrecliente || ''}}</mat-card-title>
                                <mat-card-subtitle>NO:{{ item.sg123 || ''}}</mat-card-subtitle>
                            </mat-card-header>
                            <mat-card-content>
                                <table>
                                    <tr>
                                        <td>
                                            <mat-icon>local_play</mat-icon>
                                        </td>
                                        <td>Orden</td>
                                        <td>{{ item.orden }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <mat-icon>person</mat-icon>
                                        </td>
                                        <td>Email</td>
                                        <td>{{ item.email }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <mat-icon>event_available</mat-icon>
                                        </td>
                                        <td>Fecha retiro</td>
                                        <td>{{ item.fecharetiro | date: 'dd/MM/yyyy' }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <mat-icon>access_time</mat-icon>
                                        </td>
                                        <td>Ventana</td>
                                        <td>{{ item.ventana }}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <mat-icon>timer</mat-icon>
                                        </td>
                                        <td>Tiempo de espera</td>
                                        <td>{{ item.tiempo }}</td>
                                    </tr>
                                </table>
                            </mat-card-content>
                            <mat-card-footer>
                                <table>
                                    <tr>
                                        <td><mat-icon>insert_comment</mat-icon></td>
                                        <td>Comentario </td>
                                        <td>{{ item.comentario_runner }}</td>
                                    </tr>
                                    <tr *ngIf="item.patente_retira">
                                        <td><mat-icon>money</mat-icon></td>
                                        <td>Patente</td>
                                        <td>{{ item.patente_retira }}</td>
                                    </tr>
                                </table>
                            </mat-card-footer>
                        </mat-card>
                </cdk-virtual-scroll-viewport>
            </div>
        </mat-expansion-panel>


        <mat-expansion-panel [disabled]="!(pickupService.pedidosEnCamino.length>0)" [expanded]="pickupService.pedidosEnCamino.length>0" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header  style="flex-grow:1">
                <mat-panel-title>
                    <mat-icon>directions_car</mat-icon>&nbsp;Clientes en camino
                </mat-panel-title>
                <mat-panel-description  style="flex-grow:0;height:30px;padding-right:10px;">
                    <div matBadge="{{ pickupService.pedidosEnCamino.length }}" matBadgeSize="large"></div>
                </mat-panel-description>
            </mat-expansion-panel-header>
        <div class="example-viewport" cdkVirtualScrollingElement  style="min-height: 450px;">
            <cdk-virtual-scroll-viewport #cmp  itemSize="5">

                <mat-card *cdkVirtualFor="let item of pickupService.pedidosEnCamino; let index = index;
                let count = count;
                let first = first;
                let last = last;
                let even = even;
                let odd = odd;" (click)="goOpciones(item);">
                    <mat-card-header [ngClass]="{'success' : item.segundos <= 180, 'warning' : item.segundos > 180 && item.segundos <= 300 , 'danger' : item.segundos > 300}">
                        <img *ngIf="item.tipo=='food'" class="icontipo" src="assets/images/sod.png">
                        <img *ngIf="item.tipo=='BT'" class="icontipo" src="assets/images/babytuto.png">
                        <img *ngIf="item.tipo=='Catex'" class="icontipo" src="assets/images/catex.png">
                        
                        <mat-card-title>#{{ index+1 }}&nbsp;{{ item.nombrecliente || ''}}</mat-card-title>
                        <mat-card-subtitle>NO:{{ item.sg123 || ''}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <table>
                            <tr>
                                <td>
                                    <mat-icon>local_play</mat-icon>
                                </td>
                                <td>Orden</td>
                                <td>{{ item.orden }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-icon>person</mat-icon>
                                </td>
                                <td>Email</td>
                                <td>{{ item.email }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-icon>event_available</mat-icon>
                                </td>
                                <td>Fecha retiro</td>
                                <td>{{ item.fecharetiro | date: 'dd/MM/yyyy' }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-icon>access_time</mat-icon>
                                </td>
                                <td>Ventana</td>
                                <td>{{ item.ventana }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-icon>business_center</mat-icon>
                                </td>
                                <td>Bultos</td>
                                <td>{{ item.bultos.length }}</td>
                            </tr>
                        </table>
                    </mat-card-content>
                    <mat-card-footer>
                        <table>
                            <tr>
                                <td>
                                    <mat-icon>person</mat-icon>
                                </td>
                                <td>
                                    Llega en
                                </td>
                                <td>
                                    {{ item.eta }}
                                </td>
                            </tr>
                        </table> 
                    </mat-card-footer>
                </mat-card>




            </cdk-virtual-scroll-viewport>

            </div>
        </mat-expansion-panel>


        <mat-expansion-panel [disabled]="!(pickupService.pedidosProgramadosFiltrados.length>0)"  [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header  style="flex-grow:1">
                <mat-panel-title>
                    <mat-icon>timelapse</mat-icon>&nbsp;Pedidos programados para hoy
                </mat-panel-title>
                <mat-panel-description  style="flex-grow:0;height:30px;padding-right:10px;">

                    <div matBadge="{{ pickupService.pedidosProgramadosFiltrados.length }}" matBadgeSize="large"></div>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div style="padding-left: 15px;">
                <mat-form-field appearance="outline" style="width:50%">
                    <mat-label>Filtrar</mat-label>
                    <input matInput placeholder="Ingrese nombre, sg, orden"  (keyup)="borrarBusqueda($event)" [(ngModel)]="pickupService.inputBuscar">

                </mat-form-field>
                <button mat-fab color="primary" [disabled]="!(pickupService.inputBuscar.length>=4)" (click)="buscarProgramados($event)"  style="margin-left:20px;top: 10px;"><mat-icon>search</mat-icon></button>
                <button mat-fab color="basic"  [disabled]="!(pickupService.inputBuscar.length>=1)" (click)="limpiarBusqueda()"  style="margin-left:5px;top: 10px;"><mat-icon>backspace</mat-icon></button>
            </div>
            <div class="example-viewport" cdkVirtualScrollingElement  style="min-height: 450px;">

                <div *ngIf="!(pickupService.pedidosProgramadosFiltrados.length>0)" style="text-align: center; padding:20px; color:gray; border-style: dotted;">
                    No hay resultados
                </div>

                <cdk-virtual-scroll-viewport #cmp itemSize="1">

                    <mat-card *cdkVirtualFor="let item of pickupService.pedidosProgramadosFiltrados; let index = index;
                        let count = count;
                        let first = first;
                        let last = last;
                        let even = even;
                        let odd = odd;" (click)="goOpciones(item);">

                        <mat-card-header [ngClass]="{'success' : item.segundos <= 180, 'warning' : item.segundos > 180 && item.segundos <= 300 , 'danger' : item.segundos > 300}">
                            <img *ngIf="item.tipo=='food'" class="icontipo" src="assets/images/sod.png">
                            <img *ngIf="item.tipo=='BT'" class="icontipo" src="assets/images/babytuto.png">
                            <img *ngIf="item.tipo=='Catex'" class="icontipo" src="assets/images/catex.png">

                            <mat-card-title>#{{ index+1 }}&nbsp;{{ item.nombrecliente || ''}}</mat-card-title>
                            <mat-card-subtitle>NO:&nbsp;{{ item.sg123 || ''}}</mat-card-subtitle>
                        </mat-card-header>
                        <mat-card-content>
                            <table>
                                <tr>
                                    <td>
                                    <mat-icon>local_play</mat-icon>
                                    </td>
                                    <td>Orden</td>
                                    <td>{{ item.orden }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-icon>person</mat-icon>
                                    </td>
                                    <td>Email</td>
                                    <td>{{ item.email }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-icon>event_available</mat-icon>
                                    </td>
                                    <td>Fecha retiro</td>
                                    <td>{{ item.fecharetiro | date: 'dd/MM/yyyy' }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-icon>access_time</mat-icon>
                                    </td>
                                    <td>Ventana</td>
                                    <td>{{ item.ventana }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-icon>business_center</mat-icon>
                                    </td>
                                    <td>Bultos</td>
                                    <td>{{ item.bultos.length }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-icon>monetization_on</mat-icon>
                                    </td>
                                    <td>Pago</td>
                                    <td>
                                        <span *ngIf="item.pago; else pendiente" class="badge badge-success">Pagado</span>
                                        <ng-template #pendiente>
                                            <span class="badge badge-warning">Pendiente</span>
                                        </ng-template>
                                    </td>
                                </tr>
                            </table>
                        </mat-card-content>
                    </mat-card>
                    <div style="text-align: center; margin-right: 20px;">
                            <button mat-raised-button color="primary"  [disabled]="!(pickupService.inputBuscar.length>=4)"  (click)="(gotoBuscar())"><mat-icon>search</mat-icon>Buscar mas ...</button>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</section>
<!--
<button mat-fab  color="primary" (click)="test()" style="position:absolute; right:100px; bottom:20px"><mat-icon>keyboard_arrow_up</mat-icon></button>-->


<div class="footer" #footer>
    <button mat-fab  color="primary" (click)="ir_inicio()" style="position:absolute; right:20px; bottom:20px"><mat-icon>keyboard_arrow_up</mat-icon></button>
    <button mat-fab  color="primary" (click)="recargar()" style="position:absolute; left:20px; bottom:20px"><mat-icon>autorenew</mat-icon></button>
</div>