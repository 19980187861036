import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { SocketService } from 'src/app/services/socket.service';
import { ScannerComponent } from 'src/app/template/scanner/scanner.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @ViewChild('email') email: ElementRef | undefined;

  modoAccesoQR:boolean=false;
  inputLogin:string='';
  showPass:boolean=false;
  activarQR:boolean=false;
  numberValid:boolean=false;
  helper:boolean=false;
  hide_password:boolean=true;
  labelErrorIngresoRut:boolean = false;
  rut:string = '';

  screen_height:number = window.screen.height;
  screen_width:number = window.screen.width;

  constructor(public pickupService : PickupService, private router: Router, public loginService : LoginService, private utilityService: UtilitiesService,
    private socketService: SocketService, public dialog: MatDialog) {

    this.utilityService.getObject('celular').then(val=>{
      if(val){
        const res = JSON.parse(val);
        this.pickupService.runner.celular = res.celular;
      }
    }

    );
  }

  ngOnInit(): void {
    if(this.pickupService.usuario.id != -1){
      this.router.navigate(['/inicio']);
    }else{
      this.socketService.stopAudio();
      // this.socketService.socket.emit('leave', this.provService.local);
      this.socketService.socket.disconnect();
    }
  }

  login(form:any){

    // return console.log(form.form.value);
    this.loginService.login(form);
  }


  changeIngresoState(){
    const modal = this.dialog.open(ScannerComponent, {
      data: {},
      height: (this.utilityService.modal_height) + 'px',
      width: (this.utilityService.modal_width) + 'px',
      maxHeight : (this.screen_height) + 'px',
      maxWidth : (this.screen_width) + 'px',
      disableClose: true,
    });

      modal.afterClosed().subscribe((response:any) => {});
  }

  numberOnlyValidation(){
    let input_value = document.getElementById('qr_number') as HTMLInputElement;

    if(input_value.value.length > 10) return input_value.value = input_value.value.substring(0, 10);

    let cleaned_number = input_value.value.replace(/[^0-9]/g, "");

    input_value.value = cleaned_number;

    this.pickupService.runner.celular = cleaned_number;
  }

  showPassword(){
    console.log('show');
    this.showPass = !this.showPass;
  }


  resetPassword(){

    // @ts-ignore
    const inputValue = this.email.nativeElement.value;
    window.open('https://clicyretira.ubq.cl/reset?_username='+inputValue, '_blank');
    console.log('Valor del campo de texto:', inputValue);


  }


}
