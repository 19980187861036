import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-picker-manual-bultos-prep',
  templateUrl: './picker-manual-bultos-prep.component.html',
  styleUrls: ['./picker-manual-bultos-prep.component.scss']
})
export class PickerManualBultosPrepComponent {

  bultos:any=[];
  bultos_seleccionados:any = [];
  bultos_asignados:any=[];
  bultos_noasignados:any=[];

  constructor(public pickupService : PickupService,public http: HttpClient, public utilityService: UtilitiesService) {

    this.bultos_asignados=[];
    this.bultos_noasignados=[];

      this.bultos = this.pickupService.pedidoActivo.productos;
      console.log(this.bultos);

      this.bultos.forEach((b:any) => {

          if(b.ubicacion){

            this.bultos_asignados.push(b);

          }else{

            this.bultos_noasignados.push(b);

          }

      });
  }

async resetUbicacion(id:any){

  var _self=this;
  this.http.post(environment.api_host+"/api/bultos-reset-ubicacion",{sg123:this.pickupService.pedidoActivo.sg123,id:id}).subscribe(data=>{
       
    _self.bultos_asignados=[];
    _self.bultos_noasignados=[];
    _self.bultos = data;

    _self.bultos.forEach(function(b:any){

        if(b.ubicacion){

          _self.bultos_asignados.push(b);

        }else{

          _self.bultos_noasignados.push(b);

        }

    });

  });

/*
  this.bultos_asignados=[];
  this.bultos_noasignados=[];
    var producto = this.bultos.filter((e:any) => e.codigo_bulto==id)[0];
    console.log(producto.descripcion);

    producto.ubicacion=null;
    this.bultos.forEach((b:any) => {

      if(b.ubicacion){

        this.bultos_asignados.push(b);

      }else{

        this.bultos_noasignados.push(b);

      }
  });
  this.actualizarBultos();

 */
  }

  async asignarUbicaciones(){

    var _self=this;
    this.http.post(environment.api_host+"/api/bultos-registrar-ubicaciones",{sg123:this.pickupService.pedidoActivo.sg123,bultos:this.bultos_noasignados,ubicacion:this.pickupService.codigoPosicion}).subscribe(data=>{
      _self.bultos_asignados=[];
      _self.bultos_noasignados=[];
      _self.bultos = data;

      _self.bultos.forEach(function(b:any){

          if(b.ubicacion){

            _self.bultos_asignados.push(b);

          }else{

            _self.bultos_noasignados.push(b);

          }

      });

      console.log(data);

    });
/*

    this.bultos_asignados=[];
    this.bultos_noasignados=[];

    this.bultos.forEach((b:any) => {

      if(!b.ubicacion && b.isChecked){
        b.ubicacion = this.pickupService.codigoPosicion;
      }

      if(b.ubicacion){

        this.bultos_asignados.push(b);

      }else{

        this.bultos_noasignados.push(b);

      }

  });

    this.actualizarBultos();

    console.log(this.bultos);*/


  }

  seleccionarBulto(id:any){
    console.log(id);
  }


  async actualizarBultos(){

    console.log('actualizar bultos...');
    var payload = {sg123:this.pickupService.pedidoActivo.sg123,productos:this.pickupService.pedidoActivo.productos};
    console.log(payload);

    await this.utilityService.loadingScreen();
    this.http.post(environment.api_host+"/api/actualizar-ubicacion-recepcion",{sg123:this.pickupService.pedidoActivo.sg123,productos:this.pickupService.pedidoActivo.productos}).subscribe(data=>{

      console.log(data);
      this.utilityService.dismissScreen();

    },(error:any)=>{
      this.utilityService.dismissScreen();
    });

  }


  ngOnInit() {
  }

}

