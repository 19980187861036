<section>
    <div>
        <mat-card>
            <mat-card-header>
                <mat-card-title>Seleccione modo de entrega</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div>
                    <p>Confirma la entrega del pedido con un código único otorgado por el cliente</p>
                    <button mat-raised-button color="primary" class="button btnlarge" (click)="presentModalInstruccionesCodigo()"><mat-icon>vpn_key</mat-icon>&nbsp;Código único</button>
                </div>
                <div>
                    <p>Confirma la entrega del pedido con una foto de los bultos</p>
                    <button mat-raised-button color="primary" class="button btnlarge" (click)="presentModalInstruccionesFoto()"><mat-icon>photo_camera</mat-icon>&nbsp;Entrega con Foto</button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="exit">
        <button mat-raised-button color="primary" class="button btnlarge" (click)="volver()" >Volver al Pedido</button>
    </div>
</section>