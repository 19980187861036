import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebcamModule } from 'ngx-webcam';

import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { LoginComponent } from './authentication/login/login.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { environment } from 'src/environments/environment';
import { HeaderToolbarComponent } from './template/header-toolbar/header-toolbar.component';
import { SidenavContentComponent } from './template/sidenav-content/sidenav-content.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { OpcionesComponent } from './pages/opciones/opciones.component';
import { InstruccionesfotoComponent } from './modal/instruccionesfoto/instruccionesfoto.component';
import { InforetiraComponent } from './modal/inforetira/inforetira.component';
import { PickerManualComponent } from './pages/opciones/picker-manual/picker-manual.component';
import { PickerManualBultosComponent } from './pages/opciones/picker-manual/picker-manual-bultos/picker-manual-bultos.component';
import { PickerManualBultosPrepComponent } from './pages/picker-manual-bultos-prep/picker-manual-bultos-prep.component';
import { PickerManualPrepComponent } from './pages/picker-manual-prep/picker-manual-prep.component';
import { PuertasComponent } from './pages/puertas/puertas.component';
import { ReagendarComponent } from './pages/opciones/reagendar/reagendar.component';
import { RechazobultoComponent } from './pages/rechazobulto/rechazobulto.component';
import { ResultadoComponent } from './pages/resultado/resultado.component';
import { RunnersComponent } from './pages/runners/runners.component';
import { RutRunnerComponent } from './pages/rut-runner/rut-runner.component';
import { BodegaComponent } from './pages/bodega/bodega.component';
import { BuscarComponent } from './pages/buscar/buscar.component';
import { ColaenvioComponent } from './pages/colaenvio/colaenvio.component';
import { ComprasPreparacionComponent } from './pages/compras-preparacion/compras-preparacion.component';
import { InfoRetiraComponent } from './pages/info-retira/info-retira.component';
import { LlegadaclienteComponent } from './pages/llegadacliente/llegadacliente.component';
import { MenumoduloComponent } from './pages/menumodulo/menumodulo.component';
import { NombrerunnerComponent } from './pages/nombrerunner/nombrerunner.component';
import { PickerComponent } from './pages/picker/picker.component';
import { VentanaConfirmacionComponent } from './modal/ventana-confirmacion/ventana-confirmacion.component';
import { QrCredentialComponent } from './authentication/qr-credential/qr-credential.component';
import { ScannerComponent } from './template/scanner/scanner.component';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatExpansionModule} from '@angular/material/expansion';
import { OpcionesEntregaComponent } from './pages/opciones/opciones-entrega/opciones-entrega.component';
import { InstruccionescodigoComponent } from './modal/instruccionescodigo/instruccionescodigo.component';
import {MatBadgeModule} from '@angular/material/badge';
import { RecepcionBtComponent } from './pages/recepcion-bt/recepcion-bt.component';
import {MatTableModule} from '@angular/material/table';
import { ComentarioComponent } from './pages/opciones/comentario/comentario.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { DisclaimerComponent } from './modal/disclaimer/disclaimer.component';
import { EncuestaComponent } from './modal/encuesta/encuesta.component';
import {MatRadioModule} from '@angular/material/radio';
import { CambiopasswordComponent } from './modal/cambiopassword/cambiopassword.component';
import { ProductShowcaseComponent } from './pages/opciones/components/product-showcase/product-showcase.component';
import { ProductItemComponent } from './pages/opciones/components/product-item/product-item.component';
import { ImageLoaderDirective } from './directives/image-loader.directive';

const config: SocketIoConfig = { url: environment.socket_url, options: {withCredentials: false} };

const materialModules = [
  MatDialogModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatSnackBarModule,
  MatBottomSheetModule,
  MatGridListModule,
  MatInputModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatListModule,
  MatCardModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatSelectModule,
  MatProgressBarModule,
  MatBadgeModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatSlideToggleModule,
  MatRadioModule
];

@NgModule({
  declarations: [
    AppComponent, LoginComponent, LogoutComponent, HeaderToolbarComponent, SidenavContentComponent,
    InicioComponent, OpcionesComponent, InstruccionesfotoComponent, InforetiraComponent, PickerManualComponent,
    PickerManualBultosComponent, PickerManualBultosPrepComponent, PickerManualPrepComponent, PuertasComponent, ReagendarComponent,
    RechazobultoComponent, ResultadoComponent, RunnersComponent, RutRunnerComponent, BodegaComponent, BuscarComponent,
    ColaenvioComponent, ComprasPreparacionComponent,InfoRetiraComponent,LlegadaclienteComponent,MenumoduloComponent,
    NombrerunnerComponent, PickerComponent, VentanaConfirmacionComponent, QrCredentialComponent, ScannerComponent,
    OpcionesEntregaComponent, InstruccionescodigoComponent, RecepcionBtComponent, ComentarioComponent, DisclaimerComponent, EncuestaComponent, CambiopasswordComponent, ProductShowcaseComponent, ProductItemComponent, ImageLoaderDirective

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    WebcamModule,
    ScrollingModule,
    NgxScannerQrcodeModule,
    SocketIoModule.forRoot(config),
    ...materialModules,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
