import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Socket } from 'ngx-socket-io';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment.production';
import { Md5 } from 'ts-md5';
import { PickupService } from './pickup.service';
import { UtilitiesService } from './utilities.service';
import { Pedido } from '../interfaces/Pedido';
import packagejson from '../../../package.json';
@Injectable({
  providedIn: 'root'
})
export class QueueService {
  public appVersion: string = packagejson.version;
  interval_update_pedidos:any;
  interval_cola:any;

  lock:boolean = true;

  last_queue_process_time:any = '';

  constructor(public http: HttpClient, public socket: Socket, private provService : PickupService, private utilityService: UtilitiesService) {};

    async queueHandler(){
      console.log('Se ha iniciado el servicio de envió de datos por cola');
      if(!(await this.utilityService.getObject('cola_firma'))) await this.utilityService.setObject('cola_firma', []);
      if(!(await this.utilityService.getObject('cola_imagen'))) await this.utilityService.setObject('cola_imagen', []);

      this.interval_update_pedidos = setInterval(() => {
        this.provService.actualizarTiempoEspera();
        this.provService.actualizarLlegadaEstimada();
      }, 1000);

      this.interval_cola = setInterval(async () => {

          if (!this.lock) this.lock = true;
          else return;

          await this.procesarCola();
          await this.procesarColaFoto();

          this.lock = false;
      }, 5 * 1000);

      setTimeout(() => {
        this.lock = false;
      }, 20000);
    }

    async agregarColaLoop(pedidoActivo:Pedido) {

      if(this.lock) return setTimeout(() => {

        this.agregarColaLoop(pedidoActivo)
      }, 300);

      this.agregarCola(pedidoActivo);
    }

    async agregarCola(pedidoActivo:Pedido) {

      // if(this.provService.cola_firma.length){

      let cola_firma = JSON.parse(await this.utilityService.getObject('cola_firma'));
      let cola_imagen = JSON.parse(await this.utilityService.getObject('cola_imagen'));

      cola_firma = Array.isArray(cola_firma) ? cola_firma : [];
      cola_imagen = Array.isArray(cola_imagen) ? cola_imagen : [];

      // let shallow_firma = [...this.provService.cola_firma];

      // console.log('shallow_firma', shallow_firma);

      // shallow_firma.forEach(firma => {
      let params = {
        orden: pedidoActivo.orden,
        sg123: pedidoActivo.sg123,
        imagen_firma: pedidoActivo.imagen_firma,
        fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
        fecha_runner: moment().format("YYYY-MM-DD HH:mm:ss"),
        evidencia_entrega: pedidoActivo.evidencia_entrega,
        rut_retira: pedidoActivo.rut_retira,
        patente_retira: pedidoActivo.patente_retira,
        tipo_entrega: pedidoActivo.tipo_entrega,
        version: this.appVersion
      };

      console.log( 'params', params);

      cola_firma.push(params);
      if(pedidoActivo.tipo_entrega === 'foto') cola_imagen.push(params);
      // });

      // this.provService.cola_firma.splice(0, shallow_firma.length);

      try {
        await this.utilityService.setObject("cola_firma", cola_firma);
        await this.utilityService.setObject("cola_imagen", cola_imagen);

        pedidoActivo.loader_entrega = 'exito';


      } catch (error) {
        console.error(error);
        pedidoActivo.loader_entrega = 'error';
      }
      // }
    }

    //ORDEN

    async procesarCola() {
      let fecha_disp = moment().format("YYYY-MM-DD HH:mm:ss");

      let queue_firma = JSON.parse(await this.utilityService.getObject('cola_firma'));

      if (queue_firma.length) {
        let firma = queue_firma[0];

        let httpParams = new HttpParams()
          .append("runner_id", this.provService.usuario.id.toString())
          .append("orden", firma.sg123)
          .append("fecha", firma.fecha)
          .append("fecha_runner", firma.fecha_runner)
          .append("fecha_disp", fecha_disp)
          .append("evidencia_entrega", firma.evidencia_entrega)
          .append("rut_retira", firma.rut_retira)
          .append("patente_retira", firma.patente_retira)
          .append("tipo_entrega", firma.tipo_entrega)
          .append("version_app", firma.version);

        try {
          await this.http.post(environment.api_host+"/api/firmar-entrega-app-socket", httpParams).pipe(timeout(5 * 1000) ,catchError(async e => {
            throw Error('TimeOut');
          })).toPromise();

          queue_firma.shift();
          this.utilityService.setObject("cola_firma", queue_firma);
          // this.provService.getPedidos();
        } catch (error) {
          console.error(error);
          this.last_queue_process_time = this.utilityService.getCurrentTime();
        }
      }
    }

    //FOTO

    async procesarColaFoto(){
      let md5 = new Md5();
      let queue_imagen = JSON.parse(await this.utilityService.getObject('cola_imagen')) ?? [];

      if (queue_imagen.length) {

        let imagen = queue_imagen[0];

        let httpParamsImagen = new HttpParams().append("orden", imagen.sg123).append("imagen_firma", imagen.imagen_firma);

        let encrypted_64base = md5.appendStr(imagen.imagen_firma).end()?.toString();

        try {
          let raw_data = await this.http.post(environment.api_host+"/api/firmar-entrega-app-socket-imagen",httpParamsImagen).pipe(timeout(5 * 1000) ,catchError(async e => {
            throw Error('TimeOut');
          })).toPromise();

          if(raw_data == encrypted_64base){
            queue_imagen.shift();
            this.utilityService.setObject("cola_imagen", queue_imagen);
          }else{
            console.error('Posible error al enviar la foto. Los MD5 no coinciden');
          }
        } catch (error) {
          console.error(error);
          this.last_queue_process_time = this.utilityService.getCurrentTime();
        }
      }
    }
  }
