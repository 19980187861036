import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import {Pedido} from "../../interfaces/Pedido";

@Component({
  selector: 'app-instruccionescodigo',
  templateUrl: './instruccionescodigo.component.html',
  styleUrls: ['./instruccionescodigo.component.scss']
})
export class InstruccionescodigoComponent {


  @ViewChild('codigo0') codigo0: ElementRef | undefined;
  @ViewChild('codigo1') codigo1: ElementRef | undefined;
  @ViewChild('codigo2') codigo2: ElementRef | undefined;
  @ViewChild('codigo3') codigo3: ElementRef | undefined;

  codigo_inputs: (ElementRef<any> | undefined)[] = [];

  code:string = '';
  code_error:boolean = false;
  msg_error:string='';

    constructor(
      public dialog: MatDialog,
      private pickupService: PickupService,
      public utilityService : UtilitiesService,
      private dialogRef: MatDialogRef<InstruccionescodigoComponent>
      ) {}

    ngOnInit(): void {
      this.code_error = false;
      console.log(this.pickupService.pedidoActivo);
    }

    ngAfterViewInit(){
      setTimeout(() => {
        this.codigo_inputs = [this.codigo0, this.codigo1, this.codigo2, this.codigo3];
      }, 250);
    }

    entregarCodigo(){
      const pin = this.pickupService.pedidoActivo.pin;

      const codigo = this.codigo_inputs.reduce((acc, curr) => {
        return acc + (curr?.nativeElement.value ?? '');
      }, '');

      const pedidosEsperandoCliente = this.pickupService.pedidosEsperando.filter((pedido: any) => pedido.rut === this.pickupService.pedidoActivo.rut);
      const pedidosProgramadosCliente = this.pickupService.pedidosProgramados.filter((pedido: any) => pedido.rut === this.pickupService.pedidoActivo.rut);
      const pedidosEnCaminoCliente:Pedido[] = this.pickupService.pedidosEnCamino.filter((pedido: any) => pedido.rut === this.pickupService.pedidoActivo.rut);
/*
      if(!pedidosEsperandoCliente.length && !pedidosProgramadosCliente.length){
        this.msg_error = 'Pedido ya entregado por otro runner.';
        this.code_error = true;
        return;
      }*/


      const existePinEsperando =  pedidosEsperandoCliente.some((pedido: any) => pedido.pin == codigo);
      const existePinProgramado = pedidosProgramadosCliente.some((pedido: any) => pedido.pin == codigo);
      const existePinEnCamino = pedidosEnCaminoCliente.some((pedido: any) => pedido.pin == codigo);

      if(existePinEsperando || existePinProgramado || existePinEnCamino || pin==codigo){
        this.dialogRef.close({success: true});
      }else{

        this.msg_error = 'Código Invalido. Vuelva a revisar el código con el cliente e inténtelo nuevamente.';
        this.code_error = true;
      }

    }

    patternCheck(event: any){
      let input_element:HTMLInputElement = this.codigo_inputs.find(i => i?.nativeElement.id === event.srcElement.id)?.nativeElement ?? new ElementRef<any>(null).nativeElement;

      if(input_element.value.length > 1) input_element.value = input_element.value.slice(0,1);

      const pattern = new RegExp("/[0-9]+$/");
      if(!pattern.test(input_element.value)) {
        input_element.value = input_element.value.replace(/[^0-9]/g, '');
        if(input_element.value.length < 1 && event.key !== 'Backspace') return;
      }

      if(event.key === 'Backspace' || event.key === 'ArrowLeft'){
        if(event.srcElement.id > 0){
          this.codigo_inputs[+event.srcElement.id - 1]?.nativeElement.focus();
        }
      }else{
        if(event.srcElement.id < 3 || event.key === 'ArrowRight'){
          this.codigo_inputs[+event.srcElement.id + 1]?.nativeElement.focus();
        }
      }
    }

    volver(): void {
      this.dialogRef.close({success: false});
    }
}
