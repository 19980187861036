import { HttpClient, HttpParams } from '@angular/common/http';
import {Component, HostListener, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { PickupService } from 'src/app/services/pickup.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { environment } from 'src/environments/environment';
import { InforetiraComponent } from '../../modal/inforetira/inforetira.component';
import { InstruccionesfotoComponent } from '../../modal/instruccionesfoto/instruccionesfoto.component';
import { VentanaConfirmacionComponent } from '../../modal/ventana-confirmacion/ventana-confirmacion.component';
import { Bulto } from 'src/app/interfaces/Bulto';
import { StatusLogService } from 'src/app/services/status-log.service';

@Component({
  selector: 'app-opciones',
  templateUrl: './opciones.component.html',
  styleUrls: ['./opciones.component.scss']
})
export class OpcionesComponent {

  bultos:Bulto[] = [];
  all_bultos_check: boolean = false;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    public pickupService : PickupService,
    public http: HttpClient,
    public utilityService : UtilitiesService,
    private statusService:StatusLogService) { }

  @HostListener('window:focus', ['$event'])
  onFocus(event:any) {
    console.log("Back to focus", event);
    // if(this.on_photo){
    //   // this.utilityService.loadingScreen();
    // }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(InstruccionesfotoComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  comentario:string='';

  id_uso_camara:string = '';
  on_photo:boolean = false;

  screen_height:number = window.screen.height;
  screen_width:number = window.screen.width;

  async ngOnInit() {
    if(!this.pickupService.pedidoActivo.sg123){
      let pedidoActivo = JSON.parse(await this.utilityService.getObject('pedidoActivo'));

      if(pedidoActivo.null) this.router.navigate(['/inicio']);
      else this.pickupService.pedidoActivo = pedidoActivo;
    }

    this.all_bultos_check = false;

    console.log(this.pickupService.pedidoActivo);

    this.statusService.changePedidoStatus(this.pickupService.pedidoActivo, 1);

    this.fillBultosCheckeados();

  }

  private fillBultosCheckeados(){
    this.bultos = this.pickupService.pedidoActivo.bultos.map((bulto:Bulto) => {
      return bulto;
    });

    if(this.bultos.length) this.all_bultos_check = this.bultos.every((bulto:Bulto) => +bulto.en_carro);

  }

  logLlamarCliente(telefono:string){


    sessionStorage.setItem('ultimo_sg_llamado',this.pickupService.pedidoActivo.sg123);

    let log_info = {
      telefono: `+${telefono}`,
      pedido: this.pickupService.pedidoActivo.sg123,
      id_usuario: this.pickupService.usuario.id,
      entrante: 0,
      codigo_local: this.pickupService.usuario.codigo_local,
    }

    this.pickupService.logLlamarCliente(log_info);
  }

  changeBultoState(event:any, bulto:Bulto){

    bulto.en_carro = event.checked;

    this.pickupService.checkearBulto(bulto);

    this.statusService.changePedidoStatus(this.pickupService.pedidoActivo, 3);

    if(this.bultos.length){
      this.all_bultos_check = this.bultos.every((bulto:Bulto) => +bulto.en_carro);

      if(this.all_bultos_check){
        this.statusService.changePedidoStatus(this.pickupService.pedidoActivo, 4);
      }
    }
  }

  async avisarLlegadaRunner(){
    this.statusService.changePedidoStatus(this.pickupService.pedidoActivo, 5);
    console.log(await this.statusService.getPedidoStatus(this.pickupService.pedidoActivo));
    this.OpcionesDeEntrega();

  }

  resetLlegada(){
    let url = environment.api_host+"/api/reset-llegada-app";
    let sg123 = this.pickupService.pedidoActivo.sg123;

    let httpParams = new HttpParams()
    .append("orden", sg123)
    //REVISAR SI EFECTIVAMENTE ES EL IDRUNNER EL USUARIO.ID
    .append("id_runner", this.pickupService.usuario.id);

    this.http.post(url,httpParams).subscribe(res=>{
      this.router.navigate(['/inicio']);
    });
  }

  asignarPedido(sg:any){
    console.log(this.pickupService.usuario);
    const url=environment.api_host+'/api/dispositivos/asignar-runner';

    this.http.post(url,{sg:sg, id_runner:this.pickupService.usuario.id}).subscribe(data=>{
        var result= JSON.parse(JSON.stringify(data));
        this.router.navigate(['/']);
    });

    console.log(sg);
  }

  productoEncontrado(orden:any){

    if(!this.pickupService.usuario.id){

      this.router.navigate(['/']);

    }else{

      const url=environment.api_host+'/api/productos-encontrados';
      this.http.post(url,{orden:orden, id_runner:this.pickupService.usuario.id}).subscribe(data=>{
          var result= JSON.parse(JSON.stringify(data));
          this.pickupService.pedidoActivo.status_runner='2';
        //this.router.navigate(['/']);
      });
    }
  }

  goComentarios(){
    this.router.navigate(['/comentarios'], { queryParams: { tipo_entrada: 'editar' }});
  }

  pickerManual(){
    this.router.navigate(['/picker-manual']);
  }

  reagendar(){
    this.router.navigate(['/reagendar']);
  }


  rechazoBulto(){
    this.router.navigate(['/rechazobulto']);
  }


  infoRetira(){
    // this.presentModalInfoRetira();
  }

  async presentModalInfoRetira() {

    const modal = this.dialog.open(InforetiraComponent, {
      disableClose: true,
    });

    modal.afterClosed().subscribe(data => {
        this.router.navigate(['/inicio']);
    });
  }

  async presentAlertConfirm() {

    var tiempoExpirado:boolean=false;
    var sg_llamado:boolean=false;
    var ultimo_sg_llamado = sessionStorage.getItem('ultimo_sg_llamado');

    if(ultimo_sg_llamado == this.pickupService.pedidoActivo.sg123){

      sg_llamado=true;

    }

    //verificar tiempo
    if(this.pickupService.pedidoActivo.ts_llegada_totem) {

      let fechaObjeto = new Date(this.pickupService.pedidoActivo.ts_llegada_totem);

      let fechaActual = new Date();
      let diferencia = fechaActual.getTime() - fechaObjeto.getTime();
      let minutosDiferencia = Math.floor(diferencia / 1000 / 60);
      if (minutosDiferencia > 5) {
        tiempoExpirado = false;//condicion cambiada
      }

      console.log('tiempo : '+ minutosDiferencia);
    }

    
     // @ts-ignore
    if(tiempoExpirado && !sg_llamado){

      const modal = this.dialog.open(VentanaConfirmacionComponent, {
        data: {
          disclaimer: `Acción no permitida, ya que el cliente lleva en espera más de 5 minutos`,
          botones_opciones:false
        },
        height: (this.utilityService.modal_height) / 1.9 + 'px',
        width: (this.utilityService.modal_width) + 'px',
        maxHeight: (this.screen_height) + 'px',
        maxWidth: (this.screen_width) + 'px',
        disableClose: true,
      });
// @ts-ignore
    }else if(!sg_llamado && (this.pickupService.pedidoActivo.notificacion_llegada=='meson' || this.pickupService.pedidoActivo.notificacion_llegada=='qr' || (this.pickupService.pedidoActivo.distancia > 0 && this.pickupService.pedidoActivo.distancia < 500))) {

      const modal = this.dialog.open(VentanaConfirmacionComponent, {
        data: {
          disclaimer: `Acción no permitida, ya que el cliente está en el local`,
          botones_opciones:false
        },
        height: (this.utilityService.modal_height) / 1.9 + 'px',
        width: (this.utilityService.modal_width) + 'px',
        maxHeight: (this.screen_height) + 'px',
        maxWidth: (this.screen_width) + 'px',
        disableClose: true,
      });

    }else{

      const modal = this.dialog.open(VentanaConfirmacionComponent, {
        data: {
          disclaimer: `¿Esta seguro/a que el cliente ${this.pickupService.pedidoActivo.nombrecliente} no ha llegado aun?`,
          botones_opciones:true
        },
        height: (this.utilityService.modal_height) / 1.5 + 'px',
        width: (this.utilityService.modal_width) + 'px',
        maxHeight: (this.screen_height) + 'px',
        maxWidth: (this.screen_width) + 'px',
        disableClose: true,
      });

      modal.afterClosed().subscribe(response => {
        if (response) {
          this.resetLlegada();
        }
      });
    }
  }

  OpcionesDeEntrega(){
    this.router.navigate(['/opciones-entrega']);
  }

  volverListado(){
    this.router.navigate(['/inicio']);
  }

}
