<section>
<div style="text-align:center; background-color: #f9f8f4;">
        <img src="assets/images/baby.jpeg" height="60"/>
</div>



<mat-form-field style="width:100%">
    <mat-label>Búsqueda</mat-label>
    <input matInput placeholder="Ingrese rut, nombre, sg, orden"  (keyup)="buscarPedido($event)" [(ngModel)]="paramBuscar">
    <mat-icon matSuffix>search</mat-icon>
</mat-form-field>



<mat-card *ngFor="let item of pedidosPreparacion; let index = index;
                let count = count;
                let first = first;
                let last = last;
                let even = even;
                let odd = odd;"   (click)="goPicker(item)">
                 
                    <mat-card-header [ngClass]="{'success' : item.segundos <= 180, 'warning' : item.segundos > 180 && item.segundos <= 300 , 'danger' : item.segundos > 300}">
                        <span style="color:white"><b>{{ item.tipo }}</b></span>
                        <span style="color:white"><b>{{ index+1 }}/{{ count }} </b></span>
                        <mat-card-title>{{ item.nombrecliente || ''}}</mat-card-title>
                        <mat-card-subtitle>{{ item.sg123 || ''}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <table style="width: 100%;">
                            <tr>

                                <td><mat-icon>local_play</mat-icon>Orden</td>
                                <td>{{ item.orden }}</td>
                            </tr>
                            <tr>

                                <td><mat-icon>person</mat-icon>RUT</td>
                                <td>{{ item.rut }}</td>
                            </tr>
                            <tr>

                                <td> <mat-icon>event_available</mat-icon>Fecha retiro</td>
                                <td>{{ item.fecharetiro | date: 'dd/MM/yyyy' }}</td>
                            </tr>
                            <tr>

                                <td><mat-icon>access_time</mat-icon>Ventana</td>
                                <td>{{ item.ventana }}</td>
                            </tr>
                            <tr>
                                <td><mat-icon>business_center</mat-icon>Productos</td>
                                <td>{{ item.productos.length }}</td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <mat-card>
                                    <table style="width: 100%;">
                                        <tr>
                                            <td><b>Código</b></td>
                                            <td><b>Descripción</b></td>
                                            <td><b>Ubicación</b></td>
                                        </tr>

                                        <tr *ngFor="let producto of item['productos']">
                                            <td>{{ producto.codigo_bulto}}</td>
                                            <td>{{ producto.descripcion}}</td>
                                            <td>{{ producto.ubicacion}}</td>
                                        </tr>
                                    </table>
                                    </mat-card>
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>


</section>