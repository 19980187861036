<mat-card>

<h1><p style="text-align: center;">¿Nos ayudas con unas preguntas?</p></h1>

<form #encuesta = "ngForm"   (ngSubmit)="submit(encuesta)" method="POST">

<!--<div *ngIf="pickupService.grupoEncuesta==0">-->
    <div>
    <h2>¿Cuál es el formato del local donde trabajas actulamente? </h2>
    <div class="form-group">

            <p><input type="radio" name="p1" value="Lider express" class = "form-control" required ngModel>Lider express</p>
            <p><input type="radio" name="p1" value="Lider (Hiper Lider)" class = "form-control" required ngModel>Lider (Hiper Lider)</p>

    </div>

    <div>
        <h2>Al momento de entregar un pedido, ¿Que tipo de entrega o despacho existe en el local?. Seleccione todas las opciones que apliquen</h2>
        <div class="form-group" required>
        
                <p><input type="checkbox" name="p2_1" value="pickup" class = "form-control" (click)="eval_group(1)" ngModel>Pickup</p>
                <p><input type="checkbox" name="p2_2" value="entrega_home_delivery" class = "form-control" ngModel>Entrega Home Delivery</p>
                <p><input type="checkbox" name="p2_3" value="entrega_lider_lat" class = "form-control" ngModel>Entrega Lider al Toque (LAT)</p>
                <p><input type="checkbox" name="p2_4" value="entrega_pickup_lockers" class = "form-control" ngModel>Entrega con Pickup Lockers</p>
                <p><input type="checkbox" name="p2_5" value="entrega_pickup_towers" class = "form-control" ngModel>Entrega Pickup Tower</p>
        </div>
    </div>

    <h2>¿Cómo es el espacio donde está ubicado el local en que trabajas?</h2>
    <div class="form-group">

            <p><input type="checkbox" name="p3_1" value="exclusivo_lider" class = "form-control"  ngModel>Local exclusivo para supermercado Lider (Puede contar dentro del mismo espacio con pequeños locales de servicios asociados, como farmacias, tabaquerias, etc.)</p>
            <p><input type="checkbox" name="p3_2" value="strip" class = "form-control"  ngModel>Dentro de un Strip center (centros comerciales de pequeño formato)</p>
            <p><input type="checkbox" name="p3_3" value="mall" class = "form-control"  ngModel>Dentro de un Mall</p>
    </div>

    

    <h2>El local donde estás, ¿Cuenta entrega Pickup en estacionamiento? </h2>
    <div class="form-group">

            <p><input type="radio" name="p4" value="SI" class = "form-control" required ngModel>SI</p>
            <p><input type="radio" name="p4" value="NO" class = "form-control" required ngModel>NO</p>

    </div>

    <h2>Al momento de entregar un pedido Pickup en el estacionamiento, ¿dónde esta ubicado este?</h2>
    <div class="form-group">

            <p><input type="radio" name="p5" value="exterior" class = "form-control" required ngModel>En el exterior primer nivel</p>
            <p><input type="radio" name="p5" value="subterraneo_1" class = "form-control" required ngModel>Estacionamiento subterraneo nivel -1</p>
            <p><input type="radio" name="p5" value="subterraneo_2" class = "form-control" required ngModel>Estacionamiento subterraneo nivel -2</p>
            <p><input type="radio" name="p5" value="otro" class = "form-control" required ngModel>Otro, especifique cuál</p>
    </div>
    <div class="form-group">
        <p>
            <input type="text" name="p5_otro" style="width:100%; height: 30px;" class = "form-control" ngModel>
        </p>
    </div>


</div>
<!--<div *ngIf="pickupService.grupoEncuesta==1">-->
<div>

    <h2>¿Qué tipo de productos se entregan en el local?  selecciona todas las opciones que apliquen </h2>
    <div class="form-group">

            <p><input type="radio" name="p3" value="solo_sod" (click)="opcionSodCatex(1)" class = "form-control" required ngModel>Productos solo Sod</p>
            <p><input type="radio" name="p3" value="sod_catex" (click)="opcionSodCatex(2)" class = "form-control" required ngModel>Productos Sod y Catex</p>
    </div>

    <!-- PREGUNTAS SOD -->


    <div *ngIf="es_sod">
    <h3  *ngIf="es_sod && !es_catex" style="color:gray">Pensando en la realizacion de entrega de pedidos SOD:</h3>
    <h3  *ngIf="es_sod && es_catex" style="color:gray">Pensando en la realizacion de entrega de pedidos SOD y Catex:</h3>
    <hr>

    <h2>Al momento de armar un pedido SOD, ¿En qué zona del local realizas esta acción?</h2>
    <div class="form-group">

            <p><input type="radio" name="p6" value="pickup_interior" class = "form-control" required ngModel>Zona Pickup ubicada al interior de la tienda</p>
            <p><input type="radio" name="p6" value="estacionamiento-1" class = "form-control" required ngModel>Zona Pickup ubicada en el sector de estacionamientos subterráneos en un primer  nivel (-1)</p>
            <p><input type="radio" name="p6" value="estacionamiento-2" class = "form-control" required ngModel>Zona Pickup ubicada en el sector de estacionamientos subterraneos en un segundo  nivel (-2)</p>
            <p><input type="radio" name="p6" value="otro" class = "form-control" required ngModel>Otro, especifique cuál</p>
            <p>
                <input type="text" name="p6_otro" style="width:100%; height: 30px;"  class = "form-control" ngModel>
            </p>
    </div>

    <h2>¿En qué sector entregas los pedidos SOD?</h2>
    <div class="form-group">

            <p><input type="radio" name="p7" value="pickup_interior" class = "form-control" required ngModel>Zona ubicada al interior de la tienda  (SAC / centro de soluciones)</p>
            <p><input type="radio" name="p7" value="estacionamiento-1" class = "form-control" required ngModel>Zona ubicada en el  sector de estacionamientos subterraneos en un primer nivel  nivel (-1)</p>
            <p><input type="radio" name="p7" value="estacionamiento-2" class = "form-control" required ngModel>Zona ubicada en el  sector de estacionamientos subterraneos en un segundo nivel  nivel (-2)</p>
            <p><input type="radio" name="p7" value="otro" class = "form-control" required ngModel>Otro, especifique cuál</p>
            <p>
                <input type="text" name="p7_otro" style="width:100%; height: 30px;"  class = "form-control" ngModel>
            </p>
    </div>
    </div>

    <!-- PREGUNTAS CATEX -->

    <div *ngIf="es_catex">
       
    <h2>Cuando te enteras que debes entregar un producto Catex, como un TV, celular , etc ¿Dónde debes ir a buscarlo?</h2>
    <div class="form-group">

            <p><input type="radio" name="p8" value="bodega_pickup" class = "form-control"  [required]="es_catex" ngModel>Bodega ubicada dentro de la zona Pickup</p>
            <p><input type="radio" name="p8" value="bodega_sector_tienda" class = "form-control"  [required]="es_catex" ngModel>Bodega ubicada dentro del sector de la tienda</p>
            <p><input type="radio" name="p8" value="otro" class = "form-control"  [required]="es_catex" ngModel>Otro, especifique cuál</p>
            <p>
                <input type="text" name="p8_otro" style="width:100%; height: 30px;"  class = "form-control" ngModel>
            </p>
    </div>

    <h2>¿En qué sector realizas entregas de pedidos CATEX?</h2>
    <div class="form-group">

            <p><input type="radio" name="p9" value="interior_tienda" class = "form-control"  [required]="es_catex" ngModel>Zona ubicada al interior de la tienda  (SAC / Centro de soluciones)</p>
            <p><input type="radio" name="p9" value="estacionamiento_ext_nivel_1" class = "form-control"  [required]="es_catex" ngModel>Zona estacionamiento exterior primer nivel</p>
            <p><input type="radio" name="p9" value="estacionamiento-1" class = "form-control"  [required]="es_catex" ngModel>Zona ubicada en el sector de estacionamientos subterráneos en un primer nivel (-1) </p>
            <p><input type="radio" name="p9" value="estacionamiento-2" class = "form-control"  [required]="es_catex" ngModel>Zona ubicada en el sector de estacionamientos subterráneos en un segundo nivel (-2)</p>
            <p><input type="radio" name="p9" value="otro" class = "form-control" [required]="es_catex" ngModel>Otro, especifique cuál</p>
            <p>
                <input type="text" name="p9_otro" style="width:100%; height: 30px;"  class = "form-control" ngModel>
            </p>

    </div>
    </div>
</div>

<!--

    <div *ngFor="let p of preguntas">
        <h2>{{ p.pregunta }}</h2>
        <div class="form-group">

            <div *ngFor="let a of p.alternativas">
                
                <p><input type="radio" name="{{p.id}}" value="{{a.id}}" class = "form-control" required ngModel>{{ a.pregunta }}</p>
                 
            </div>
        </div>

    </div>
    -->
    <div style="text-align: center;">
        <p style="color:red">{{ mensaje_error }}</p>
        <p>{{mensaje_enviando}}</p>
        <button [disabled]="enviando"  mat-raised-button color="primary" class="btnlarge" class="button" type="submit"><h2>Enviar Encuesta</h2></button>
    </div>
</form>


</mat-card>
