<div class="container">
    <h2 mat-dialog-title>
        <mat-icon>notification_important</mat-icon>
        Atención
    </h2>
    <div mat-dialog-content class="content">
        <div class="disclaimer">
            <p>Confirma la recepción con el código único del cliente.</p>
            <p>El cliente es el único que tiene conocimiento de este código, se le fue enviado previamente a su WhatsApp; también puede encontrarlo en la App Cliente.</p>
        </div>
        <div class="error" [ngClass]="{'code_error': code_error}">
            <p *ngIf="code_error">{{ msg_error }}</p>
        </div>
        <div class="codigo">
            <p>Introduce aquí tu código único otorgado por el cliente</p>
            <div class="code">
                <div class="box">
                    <input #codigo0 id="0" type="number" pattern="[0-9]*" inputmode="numeric" max="9" min="0" maxlength="1" (keyup)="patternCheck($event)">
                    <input #codigo1 id="1" type="number" pattern="[0-9]*" inputmode="numeric" max="9" min="0" maxlength="1" (keyup)="patternCheck($event)">
                    <input #codigo2 id="2" type="number" pattern="[0-9]*" inputmode="numeric" max="9" min="0" maxlength="1" (keyup)="patternCheck($event)">
                    <input #codigo3 id="3" type="number" pattern="[0-9]*" inputmode="numeric" max="9" min="0" maxlength="1" (keyup)="patternCheck($event)">
                </div>
                <div class="icon">
                    <mat-icon>vpn_key</mat-icon>
                </div>
            </div>
            <div></div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button [disabled]="codigo0.value.length < 1 || codigo1.value.length < 1 || codigo2.value.length < 1 || codigo3.value.length < 1" mat-raised-button color="primary" class="btnlarge" (click)="entregarCodigo()">Confirmar Código</button>
        <button mat-raised-button color="link" class="btnlarge" (click)="volver()"> <mat-icon>arrow_back</mat-icon>Volver a las opciones</button>
    </div>
</div>
