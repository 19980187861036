<section>


    <h3 class="sectionTitle">
        <mat-icon>shopping_basket</mat-icon> Seleccione bultos para ubicación <b>{{ pickupService.codigoPosicion }}</b>
    </h3>    
    <p *ngIf="bultos_noasignados.length==0" class="alert alert-nocontent">
        No hay bultos por asignar
    </p>
    <div>
        <mat-card  *ngIf="bultos_noasignados.length>0"> 
            <mat-card-content> 
                <table style="width:100%">
                    <tr *ngFor="let bulto of bultos_noasignados">
                        <td>{{ bulto.id }}</td>
                        <td>{{ bulto.descripcion }}</td>
                        <td>{{ bulto.ubicacion }}</td>
                        <td>
                            <input  [(ngModel)]="bulto.isChecked" type="checkbox" /> 
                        </td> 
                    </tr>
                </table>
            </mat-card-content>
            <mat-card-footer class="actionButtons">                
                <button style="width: 100%;" mat-raised-button color="primary" class="button btnlarge" (click)="asignarUbicaciones()"  *ngIf="bultos_noasignados.length>0" >
                    Asignar bultos a :<b>{{ pickupService.codigoPosicion }}</b>
                </button> 
            </mat-card-footer>
        </mat-card>
    </div>
    <h3 class="sectionTitle">
        <mat-icon>shopping_basket</mat-icon> Bultos asignados
    </h3> 
    <p *ngIf="bultos_asignados.length==0" class="alert alert-nocontent">
        No hay bultos asignados
    </p>
    <div>
        <mat-card  *ngIf="bultos_asignados.length>0"> 
            <mat-card-content> 
                <table style="width:100%">
                    <tr *ngFor="let bulto of bultos_asignados">
                        <td>{{ bulto.id }}</td>
                        <td>{{ bulto.descripcion }}</td>
                        <td>{{ bulto.ubicacion }}</td>
                       
                        <td style="text-align: right;">
                        <button mat-raised-button color="warn" class="button" (click)="resetUbicacion(bulto.id)" *ngIf="bultos_asignados.length>0"> 
                            Quitar
                        </button>
                        </td> 
                    </tr>
                </table>
            </mat-card-content>
            <mat-card-footer class="actionButtons">                
            </mat-card-footer>
        </mat-card>
    </div>
</section>