<section>
    <div>
        <mat-card> 
            <mat-card-header> 
                <mat-card-title>Buscar pedido</mat-card-title>  
            </mat-card-header> 
            <mat-card-content class="row">   
                
                
                    <mat-form-field appearance="fill">
                        <mat-label>Nombre cliente, rut o SG</mat-label>
                        <input matInput  [(ngModel)]="search_string">
                        <mat-icon matSuffix>search</mat-icon>
                    </mat-form-field>   

                  

                    <button type="button" mat-raised-button color="primary" [disabled]="search_string==''"  (click)="buscar()">BUSCAR</button>  


            </mat-card-content> 
        </mat-card>
        <mat-progress-bar mode="indeterminate" *ngIf="buscando"></mat-progress-bar>
    </div>     
    <div class="alert alert-nocontent" role="alert" *ngIf="!pedidos_encontrados?.length" >
        Sin resultados 
    </div>
    <div class="results" *ngFor="let pedido_encontrado of pedidos_encontrados">
        <mat-card> 
            <mat-card-header>
                <img *ngIf="pedido_encontrado.tipo=='food'" class="icontipo" src="assets/images/sod.png">
                <img *ngIf="pedido_encontrado.tipo=='BT'" class="icontipo" src="assets/images/babytuto.png">
                <img *ngIf="pedido_encontrado.tipo=='Catex'" class="icontipo" src="assets/images/catex.png">
                
                <mat-card-title>{{ pedido_encontrado.nombrecliente || ''}}</mat-card-title>
                <mat-card-subtitle>NO:{{ pedido_encontrado.sg123 || ''}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="row">                  
                <table style="width:100%">
                    <tr>
                        <td>
                        <mat-icon>local_play</mat-icon>
                        </td>
                        <td>Orden</td>
                        <td>{{ pedido_encontrado.orden }}</td>
                    </tr>
                    <tr>
                        <td>
                            <mat-icon>person</mat-icon>
                        </td>
                        <td>RUT</td>
                        <td>{{ pedido_encontrado.rut }}</td>
                    </tr>
                    <tr>
                        <td>
                            <mat-icon>event_available</mat-icon>
                        </td>
                        <td>Fecha retiro</td>
                        <td>{{ pedido_encontrado.fecharetiro | date: 'dd/MM/yyyy' }}</td>
                    </tr>
                    <tr>
                        <td>
                            <mat-icon>access_time</mat-icon>
                        </td>
                        <td>Ventana</td>
                        <td>{{ pedido_encontrado.ventana }}</td>
                    </tr>
                    <tr>
                        <td>
                            <mat-icon>business_center</mat-icon>
                        </td>
                        <td>Status</td>
                        <td>{{ pedido_encontrado.status }}</td>
                    </tr>
                    <tr *ngIf="pedido_encontrado.status=='Entregado'">
                        <td>
                            <mat-icon>event_available</mat-icon>
                        </td>
                        <td>Fecha entrega</td>
                        <td>{{ pedido_encontrado.ts_fin_entrega_bultos }}</td>
                    </tr>
                    <tr>
                        <td>
                            <mat-icon>business_center</mat-icon>
                        </td>
                        <td>Bultos</td>
                        <td>{{ pedido_encontrado.bultos.length }}</td>
                    </tr>
                </table> 
            </mat-card-content> 
            <mat-card-footer>
                <button  mat-raised-button color="primary"  *ngIf="pedido_encontrado.status=='Compra aceptada' || pedido_encontrado.status=='Reagendado' || pedido_encontrado.status=='Cliente esperando' || pedido_encontrado.status == 'Recepcionado' "  (click)="goOpcionesLlegada(pedido_encontrado)">Ir a opciones</button>
            </mat-card-footer>
        </mat-card>
    </div>
</section>