<section>
    <div>
        <mat-card >
            <mat-card-header> 
                <mat-card-title>Entrega</mat-card-title>  
            </mat-card-header> 
            <mat-card-content>                 
                <div class="ion-text-center">
                    <h1>Pedido entregado</h1>
                    <h1>exitosamente</h1>
                </div>
            </mat-card-content> 
            <mat-card-footer>
                <button mat-raised-button color="primary" class=" " (click)="volverListado()" > <mat-icon>arrow_back</mat-icon>Volver al listado</button>
            </mat-card-footer>
        </mat-card> 
    </div> 
    
</section>
