import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appImageLoader]'
})
export class ImageLoaderDirective implements OnInit {
  @Input('appImageLoader') src!: string;
  @Input() defaultImage = 'assets/images/defaultProductImage.png';

  private loader: HTMLElement;
  private img: HTMLImageElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.img = this.el.nativeElement;
    this.loader = this.renderer.createElement('mat-progress-spinner');
    this.renderer.setAttribute(this.loader, 'mode', 'indeterminate');
    this.renderer.setAttribute(this.loader, 'diameter', '40');
    this.renderer.setStyle(this.loader, 'position', 'absolute');
    this.renderer.setStyle(this.loader, 'top', '50%');
    this.renderer.setStyle(this.loader, 'left', '50%');
    this.renderer.setStyle(this.loader, 'transform', 'translate(-50%, -50%)');
    this.renderer.setAttribute(this.loader, 'color', 'primary');
  }

  ngOnInit() {
    this.renderer.setStyle(this.img, 'display', 'none');
    this.renderer.insertBefore(this.img.parentNode, this.loader, this.img);

    this.img.onload = () => this.showImage();
    this.img.onerror = () => this.showDefaultImage();
    this.img.src = this.src;
  }

  private showImage() {
    this.renderer.removeChild(this.img.parentNode, this.loader);
    this.renderer.setStyle(this.img, 'display', 'block');
  }

  private showDefaultImage() {
    this.img.src = this.defaultImage;
  }
}
