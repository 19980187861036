import { Component, Input, OnInit } from '@angular/core';
import { PickupService } from 'src/app/services/pickup.service';

@Component({
  selector: 'app-info-retira',
  templateUrl: './info-retira.component.html',
  styleUrls: ['./info-retira.component.scss']
})
export class InfoRetiraComponent implements OnInit {

  rut_retira:string='';
  patente_retira:string='';
  comentario:string='';


  constructor(public pickupService : PickupService) { }

  ngOnInit() {
    this.rut_retira=this.pickupService.pedidoActivo.rut;
    this.patente_retira='';
  }

  guardar(){}

  cerrar(){}

}
