<section>
    <h3 class="sectionTitle">Seleccione con cual runner operará</h3>
    <div>
        <mat-card >
            <mat-card-header>
                <mat-card-title>Entrega</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                {{ pickupService.usuario.nombre_local }}<br>
                {{ pickupService.runner.nombre }}<br>
            </mat-card-content>
        </mat-card>
    </div>
    <div>
        <mat-card *ngFor="let runner of listaRunners" (click)="seleccionarRunner(runner)">
            <mat-card-content>
                <mat-icon>person</mat-icon>
                <h2>{{ runner.nombre }}</h2>
            </mat-card-content>
        </mat-card>
    </div>
</section>