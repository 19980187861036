<mat-card>
    <mat-card-header>
        <mat-card-title>Identificación Runner</mat-card-title> 
    </mat-card-header>
    <mat-card-content>
        <p>Para completar tu registro, por favor ingresa tu nombre</p>
        <form class="example-form">
            <mat-form-field class="example-full-width">
                <mat-label>Nombre</mat-label>
                <input [(ngModel)]="nombreRunner" required>
            </mat-form-field>
        </form>
    </mat-card-content>
    <mat-card-footer>
        <button mat-raised-button color="primary" class="button" (click)="guardar()" > Guardar</button>
        <button mat-raised-button color="link" class="button" (click)="gologin()" > Voler al login</button>
    </mat-card-footer>
</mat-card>


