<section>
    <div class="title">
        <h2 class="sectionTitle">Entregas pendientes de envío</h2>
        <p>Si tienes mala señal, los pedidos entregados a clientes se encolarán acá hasta que recuperes señal.

            Sólo cuando se envíen al servidor, desaparecerán de la TV</p>
    </div>
    <div class="pedidos" *ngIf="cola.length>0">
        <mat-card  *ngFor="let pedido of cola">
            <mat-card-content>
                <div class="checkers">
                    <div class="firma">
                        <mat-icon>drive_file_rename_outline</mat-icon>
                        <div class="circle" [ngClass]="!pedido.check.firma ? 'green' : 'red'"></div>
                    </div>
                    <div class="imagen">
                        <mat-icon>wallpaper</mat-icon>
                        <div class="circle" [ngClass]="!pedido.check.imagen ? 'green' : 'red'"></div>
                    </div>
                </div>
                <div class="labels">
                    <span><mat-icon>local_play</mat-icon></span>
                    <span>SG</span>
                    <span>{{ pedido.sg123 }}</span>
                </div>
                <div class="labels">
                    <span><mat-icon>event_available</mat-icon></span>
                    <span>Fecha Entrega</span>
                    <span>{{ pedido.fecha_runner }}</span>
                </div>
                <div class="labels">
                    <span><mat-icon>merge_type</mat-icon></span>
                    <span>Tipo Entrega</span>
                    <span>{{ pedido.tipo_entrega }}</span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="alert alert-nocontent" role="alert" *ngIf="!cola.length">
        No hay pedidos pendiente de envío
    </div>
    <button class="btn btnlarge reload" mat-raised-button color="primary" (click)="setPedidosEnCola()"><mat-icon>autorenew</mat-icon>Recargar pedidos</button>
    <div class="last-time" *ngIf="queueService.last_queue_process_time">
        <span><mat-icon>more_time</mat-icon></span><span>Último intento: {{ queueService.last_queue_process_time }}</span>
    </div>
</section>
