<div class="container">
    <div class="version">
        <div class="title">
            <h3>Acción de seguridad</h3>
        </div>
        <div class="fields">
            <p class="nth1">Por motivos de seguridad, y para poder seguir utilizando la RunnerApp de Ubiquity, le solicitamos cambiar su contraseña.</p>

            <div class="helper-container">
                <p class="disclaimer">La contraseña debe cumplir los siguientes requisitos:</p>
                <ul class="helper-list">
                    <li [ngClass]="checkErrors(password.value, repeat_password.value, 'min_8') ? 'help_error' : 'success'">
                        <span *ngIf="checkErrors(password.value, repeat_password.value, 'min_8')"><mat-icon>close</mat-icon></span>
                        <span *ngIf="!checkErrors(password.value, repeat_password.value, 'min_8')"><mat-icon>check</mat-icon></span>
                        <span>Mínimo 8 caracteres</span>
                    </li>
                    <li [ngClass]="checkErrors(password.value, repeat_password.value, 'capital_letter') ? 'help_error' : 'success'">
                        <span *ngIf="checkErrors(password.value, repeat_password.value, 'capital_letter')"><mat-icon>close</mat-icon></span>
                        <span *ngIf="!checkErrors(password.value, repeat_password.value, 'capital_letter')"><mat-icon>check</mat-icon></span>
                        <span>Al menos 1 Mayúscula</span>
                    </li>
                    <li [ngClass]="checkErrors(password.value, repeat_password.value, 'lowercase_letter') ? 'help_error' : 'success'">
                        <span *ngIf="checkErrors(password.value, repeat_password.value, 'lowercase_letter')"><mat-icon>close</mat-icon></span>
                        <span *ngIf="!checkErrors(password.value, repeat_password.value, 'lowercase_letter')"><mat-icon>check</mat-icon></span>
                        <span>Al menos 1 Minúscula</span>
                    </li>
                    <li [ngClass]="checkErrors(password.value, repeat_password.value, 'special_character') ? 'help_error' : 'success'">
                        <span *ngIf="checkErrors(password.value, repeat_password.value, 'special_character')"><mat-icon>close</mat-icon></span>
                        <span *ngIf="!checkErrors(password.value, repeat_password.value, 'special_character')"><mat-icon>check</mat-icon></span>
                        <span>Al menos 1 carácter especial</span>
                    </li>
                    <li [ngClass]="checkErrors(password.value, repeat_password.value, 'number') ? 'help_error' : 'success'">
                        <span *ngIf="checkErrors(password.value, repeat_password.value, 'number')"><mat-icon>close</mat-icon></span>
                        <span *ngIf="!checkErrors(password.value, repeat_password.value, 'number')"><mat-icon>check</mat-icon></span>
                        <span>Al menos 1 numero</span>
                    </li>
                    <li [ngClass]="checkErrors(password.value, repeat_password.value, 'repeat_password') ? 'help_error' : 'success'">
                        <span *ngIf="checkErrors(password.value, repeat_password.value, 'repeat_password')"><mat-icon>close</mat-icon></span>
                        <span *ngIf="!checkErrors(password.value, repeat_password.value, 'repeat_password')"><mat-icon>check</mat-icon></span>
                        <span>Las contraseñas deben coincidir</span>
                    </li>
                </ul>
            </div>

            <div class="error" *ngIf="error">
                <p  class="error-message" >La contraseña debe ser distinta a tus ultimas contraseñas.</p>
            </div>

            <div class="password_container">
                <mat-form-field appearance="fill">
                    <mat-label>Contraseña</mat-label>
                    <input #password name="password" matInput [type]="hide_password ? 'password' : 'text'" ngModel required>
                    <button mat-icon-button matSuffix (click)="hide_password = !hide_password" [attr.aria-label]="'hide_password password'" [attr.aria-pressed]="hide_password">
                        <mat-icon>{{hide_password ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Repetir contraseña</mat-label>
                    <input #repeat_password name="repeat_password" matInput [type]="hide_repeat_password ? 'password' : 'text'" ngModel required>
                    <button mat-icon-button matSuffix (click)="hide_repeat_password = !hide_repeat_password" [attr.aria-label]="'hide_repeat_password password'" [attr.aria-pressed]="hide_repeat_password">
                        <mat-icon>{{hide_repeat_password ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div class="button">
                <button
                    [disabled]="checkErrors(password.value, repeat_password.value, 'all')"
                    [ngClass]="{'button-disabled': checkErrors(password.value, repeat_password.value, 'all')}"
                    mat-raised-button class="bg-success exit-button btnlarge" type="button"
                    (click)="changePassword(password.value, repeat_password.value)"
                >
                    Cambiar contraseña
                </button>
            </div>
        </div>
    </div>

    <div class="button">
        <button mat-raised-button color="primary" class="exit-button btnlarge" type="button" (click)="exit()">
            SALIR
        </button>
    </div>
</div>