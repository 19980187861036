import {EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Socket } from "ngx-socket-io";
import { SwUpdate } from "@angular/service-worker";
import { PlatformLocation } from "@angular/common";
import { catchError, distinctUntilChanged, map, mapTo, startWith, switchMap } from "rxjs/operators";
import { fromEvent, merge, Observable, Observer, of, timer } from "rxjs";
import { ajax } from 'rxjs/ajax';
import { MatSnackBar } from "@angular/material/snack-bar";
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  loadingPopup: any;
  loadingLabel:string='Sincronizando...';

  online: boolean = true;
  modal_width:number = 0;
  modal_height:number = 0;

  private drawerControllerEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public drawerControllerEmitter$ = this.drawerControllerEmitter.asObservable();

  onlineStatus:any;
offlineStatus:any;


  constructor(
    readonly swUpdate: SwUpdate,
    public http: HttpClient,
    public socket: Socket,
    private snackBar: MatSnackBar,
    private location: PlatformLocation) {

      this.modal_width = window.innerWidth;
      this.modal_height = window.innerHeight;

      /**
       * Detecta si está o no conectado a internet y actualiza el valor
       * de la variable online en el servicio de utilities.
       */

      // Network.addListener("networkStatusChange", (status:any) => {
      //   console.log("Network status changed", status);
      //   this.online = status.connected;
      // });
      // this.createOnline$().subscribe(isOnline => console.log('Online', isOnline));
    }

    // createOnline$() {
    //   const isOnline$ = merge(
    //     fromEvent(window, 'online').pipe(map(() => true)),
    //     fromEvent(window, 'offline').pipe(map(() => false))
    //   );
    //   const ping$ = timer(0, 5000).pipe(
    //     switchMap(() => ajax('www.google.com').pipe(
    //       map(() => true),
    //       catchError(() => of(false))
    //     ))
    //   );
    //   return merge(isOnline$, ping$).pipe(
    //     startWith(navigator.onLine),
    //     distinctUntilChanged()
    //   );
    // }

  changeDrawerState(event:boolean){
    this.drawerControllerEmitter.emit(event);
  }

  /**
   * @param timeStamp timeStamp to compare
   * @param higher if true, returns the time past till the timeStamp, if false, returns the time past since the timeStamp
   * @returns return the seconds past till/since the timeStamp
   */
  public getTimePastTill(timeStamp:string, higher:boolean = true){
    let client_hour_snapshot = new Date(timeStamp);
      if((timeStamp).toString().substr((timeStamp).toString().length - 2 ) == '0Z'){
        client_hour_snapshot.setSeconds(client_hour_snapshot.getMinutes() + client_hour_snapshot.getTimezoneOffset());
      }

      return higher ? Math.floor((+client_hour_snapshot - Date.now())/1000)
      : Math.floor((Date.now() - +client_hour_snapshot)/1000);
  }

  public leadZero(number:number){
      return (number < 10 ? '0' : '') + number;
  }

  formatoSegundos(sec:any) {
    let date = new Date();
    date.setSeconds(sec);
    return date.toISOString().substr(11, 8);
  }

  async setObject(key: string, value:any) {
    await window.localStorage.setItem(key,JSON.stringify(value));
  }

  async getObject(key:any) {
    const value  =  window.localStorage.getItem(key) ?? '{"null":true}';
    return value;
  }

  public getCurrentTime(){
    return moment().format("YYYY-MM-DD HH:mm:ss");
  }

  notificacion() {
    Notification.requestPermission(function (result) {
      if (result === "granted") {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification("Notificacion Pickup", {
            body: "Cliente esperando!",
            requireInteraction: true,
            icon: "assets/images/pickup_small.png",
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            tag: "vibration-sample",
          });
        });
      }
    });
  }

  patternCheck(element:HTMLInputElement, pattern_type = 'number'){
    let pattern: RegExp | undefined;
    let contrary_pattern: RegExp | undefined;

    switch(pattern_type){
      case 'number':
        pattern = new RegExp("/[0-9]+$/");
        contrary_pattern = new RegExp("/[^0-9]+$/");
      break;
      case 'string':
        pattern = new RegExp("/[a-zA-Z ]+$/");
        contrary_pattern = new RegExp("/[^a-zA-Z ]+$/");
      break;
      case 'email':
        pattern = new RegExp("/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/");
        contrary_pattern = new RegExp("/[^a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/");
      break;
      case 'phone':
        pattern = new RegExp("/[0-9]{3}-[0-9]{3}-[0-9]{4}$/");
        contrary_pattern = new RegExp("/[^0-9]{3}-[0-9]{3}-[0-9]{4}$/");
      break;
      case 'date':
        pattern = new RegExp("/[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/");
        contrary_pattern = new RegExp("/[^0-9]{4}\/[0-9]{2}\/[0-9]{2}$/");
      break;
      case 'rut':
        return this.rutChecker(element);

    }

    if(!pattern?.test(element.value)) element.value = element.value.replace(contrary_pattern ?? '', '');

  }

  stringReducerResume(str:string, length:number) {
    if(!str) str = '';
    return str.length > length ? str.substring(0, length) + "..." : str;
  }

  rutChecker(element:HTMLInputElement){

    const key = element.value[element.value.length - 1];

    if(element.value.length > 10) return element.value = element.value.substr(0, 10);

    let cleaned_rut = (key.toLowerCase() === 'k' && element.value.length < 8) ?
                        element.value.replace(/[^0-9-]/g, "")
                      : element.value.replace(/[^0-9kK-]/g, "");

    cleaned_rut = cleaned_rut.replaceAll('-','');

    cleaned_rut = cleaned_rut.slice(0, -1) + '-' + cleaned_rut.slice(-1);

    element.value = cleaned_rut;

  }
  // async showAlert() {
  //   let alertRet = await Modals.alert({
  //     title: "Actualización",
  //     message: "Se ha detectado una nueva actualización",
  //   });
  // }

  async presentLoading() {
    // const loading = await this.loadingController.create({
    //   cssClass: "my-custom-class",
    //   message: "Por favor espere...",
    //   duration: 1000,
    // });
    // await loading.present();

    // const { role, data } = await loading.onDidDismiss();
    console.log("Loading dismissed!");
  }

  async loadingScreen() {
    // this.loadingPopup = await this.loadingController.create({
    //   cssClass: 'my-custom-class',
    //   message: 'Por favor espere...',
    //   duration: 25000
    // });
    // await this.loadingPopup.present();
    console.log('Loading...');
  }

  async dismissScreen() {
    // await this.loadingPopup.dismiss();
    console.log('Dismissed Loading');
  }

  showSnackBar(message:string, color:string = 'success', duration:number = 10000){
    this.snackBar.open(message, 'Entendido', {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['snackbar', color]
    });
  }


  async presentToast() {
    // const toast = await this.toastController.create({
    //   message: "Ha llegado un nuevo cliente...",
    //   duration: 2000,
    // });
    // toast.present();
  }
}
