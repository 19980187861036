<div class="container">

    <h2><mat-icon>qr_code_2</mat-icon>&nbsp;&nbsp;&nbsp;Escanea el código QR&nbsp;&nbsp;&nbsp;<mat-icon>qr_code_2</mat-icon></h2>

    <ngx-scanner-qrcode class="scanner-qr" [hidden]="!action.isStart || action.isLoading" #action="scanner" [config]="config" (event)="qrScan($event)">
    </ngx-scanner-qrcode>
    <div *ngIf="number_of_devices > 0" class="change-icon" (click)="changeDevice()"><mat-icon>switch_camera</mat-icon></div>

    <h3 *ngIf="action.isLoading">⌛ Cargando la cámara de su dispositivo ⌛</h3>

    <div class="codigo" *ngIf="qr_data !== ''">
        <h3>Codigo detectado</h3>

        <p style="white-space: pre;" [ngClass]="error ? 'error' : 'success'">{{context_message}}</p>

    </div>

    <div class="footer">
        <button mat-raised-button color="primary" class="btn-volver" (click)="volverAlLogin()">
            Volver al login
        </button>
    </div>
</div>