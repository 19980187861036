import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PickupService } from 'src/app/services/pickup.service';
import { QueueService } from 'src/app/services/queue.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-colaenvio',
  templateUrl: './colaenvio.component.html',
  styleUrls: ['./colaenvio.component.scss']
})
export class ColaenvioComponent {

  cola:any = [];

  constructor(public pickupService : PickupService, public utilityService : UtilitiesService, public queueService : QueueService) { }

  async ngOnInit() {

    await this.setPedidosEnCola();
  }

  async setPedidosEnCola(){

    let firma = [];

    let queue_firma = JSON.parse(await this.utilityService.getObject('cola_firma')) ?? [];
    let queue_imagen = JSON.parse(await this.utilityService.getObject('cola_imagen')) ?? [];

    if(queue_firma?.length){
      firma = queue_firma?.map((pedido:any) => {
        pedido.check = {
          firma: true,
          imagen: queue_imagen?.length ? queue_imagen?.find((img:any) => img.orden === pedido.orden) ?? false : false
        }
        return pedido;
      });
    }

    if(queue_imagen?.length){
      firma.push(...queue_imagen?.filter((img:any) => {
        if(queue_firma.length){
          if(queue_firma?.find((pedido:any) => pedido.orden === img.orden)) return false;
        }

        img.check = {
          firma: false,
          imagen: true
        }

        return true;

      }));
    }

    this.cola = firma ?? [];

    console.log('cola_firma', this.cola);
  }
}
